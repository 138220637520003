var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          {
            staticClass: "logo",
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.toHome },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/index/logo.png"), alt: "" },
            }),
          ]
        ),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "text" }, [_vm._v("Login Account")]),
      ]),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "head-bottom" }),
    _c(
      "div",
      { staticClass: "contain" },
      [
        _c("el-card", { staticClass: "box-card" }, [
          _c("div", { staticClass: "card-head" }, [_vm._v("Login")]),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { rules: _vm.loginRules, model: _vm.currentForm },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "Email", type: "text" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleLogin.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.currentForm.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentForm, "email", $$v)
                            },
                            expression: "currentForm.email",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("img", {
                                staticClass: "header-icon",
                                staticStyle: { width: "29px", height: "29px" },
                                attrs: {
                                  src: require("@/assets/login/user_icon.png"),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "Password",
                            type: "password",
                            "show-password": "",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleLogin.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.currentForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentForm, "password", $$v)
                            },
                            expression: "currentForm.password",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("img", {
                                staticClass: "header-icon",
                                staticStyle: { width: "29px", height: "29px" },
                                attrs: {
                                  src: require("@/assets/login/password_icon.png"),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "checked-box" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.change },
                          model: {
                            value: _vm.checked,
                            callback: function ($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked",
                          },
                        },
                        [_vm._v("Remember this account")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "login-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "large", loading: _vm.loading },
                          on: { click: _vm.handleLogin },
                        },
                        [_vm._v("Log In")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "register-btn" },
                    [
                      _c("el-button", { on: { click: _vm.toRegister } }, [
                        _vm._v("Create an account"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right" }, [
      _c("span", [_vm._v("Contact Number")]),
      _c("span", { staticClass: "phone" }, [_vm._v("+6288123456789")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }