<template>
  <div class="accessories-container">
    <div class="title-container sticky">
      <div class="title-left">
        <div class="title-left-container" v-for="(item, index) in accessoryModelList" :key="item.id" @click="handleChangeModel(item, index)">
          <div class="text" :class="{active: currentModelIndex === index}">{{item.name}}</div>
          <div class="description" v-if="currentModelIndex === index"></div>
        </div>
      </div>
      <div class="title-right">
        <div class="attribute-container" v-for="(item, index) in accessoryAttributeList" :key="item.id">
          <div class="title">{{ item.name }}: </div>
          <el-dropdown @command="(e)=>handleCommand( index, e)">
            <span class="el-dropdown-link" >
              <span v-if="checkedAttribute.length > 0">{{ checkedAttribute[index]?.name }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
              <el-dropdown-item
                  v-for="i in item.children"
                  :key="i.id"
                  :command="i"
              >
                {{i.name}}
              </el-dropdown-item>
<!--              <el-dropdown-item-->
<!--                  v-for="i in item.children"-->
<!--                  :key="i.id"-->
<!--                  :command="i"-->
<!--                  :icon="checkedAttribute[index]?.id === i.id ? 'el-icon-caret-right' : ''"-->
<!--                  :style="checkedAttribute[index]?.id === i.id ? '' : 'margin-left: 27px;'"-->
<!--              >-->
<!--                {{i.name}}-->
<!--              </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="list-container"  v-loading="loading">
      <div v-if="accessoryList.length > 0">
        <div class="device-right-container">
          <div class="device-info" v-for="item in accessoryList" :key="item.id">
            <div v-if="item.cropThumbnail || item.thumbnail" class="image-container" style="cursor:pointer;" @click="toAccessoryDetail(item.id, 'toOrder')">
              <el-image :src="item.cropThumbnail || item.thumbnail"></el-image>
            </div>
            <div v-else class="example-image" style="cursor:pointer;" @click="toAccessoryDetail(item.id, 'toOrder')">
              <img src="@/assets/product/product.png" alt="">
            </div>
            <div class="info">
              <div class="info-left">
                <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
                  <div class="title">{{item.name}}</div>
                </el-tooltip>
                <div class="price">
                  <span style="margin-right: 16px">{{currency(item.discountPrice)}}</span>
                  <span style="color: #BABABA;text-decoration: line-through" v-if="item.discountPrice !== item.price">{{currency(item.price)}}</span>
                </div>
              </div>
              <div class="info-right" style="cursor: pointer" @click="toAccessoryDetail(item.id, 'toCart')">
                <img src="@/assets/index/gowuche.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="device-footer" v-if="showLoadMore" style="cursor:pointer;" @click="handleLoadMore">Load more</div>
      </div>
      <div v-else>
        <el-empty description="No Accessory"></el-empty>
      </div>
    </div>
    <Contact></Contact>
    <ProductDetail ref="productDetail"></ProductDetail>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
import {getAccessoryModel, getAccessoryAttribute, getAccessoryList} from '@/api/assessories'
import ProductDetail from "@/components/product/productDetail.vue";
import { currency } from '@/utils/util'
const defaultFilters = ()=>({
  modelId: '',
  attributeValueIds: '',
  name: ''
})
export default {
  name: 'ACCESSORIES',
  components: {ProductDetail,  Contact},
  data(){
    return {
      accessoryModelList: [],
      accessoryAttributeList: [],
      currentModelIndex: 0,
      checkedAttribute: [],
      filters:defaultFilters(),
      accessoryList: [],
      showLoadMore: false,
      displayedProductCount: 20,
      sourceAccessoryList: [],
      loading: false
    }
  },
  async mounted() {
    const {fetchAccessoryModel, fetchAccessoryList} = this
    await fetchAccessoryModel()
    fetchAccessoryList()
  },
  methods:{
    currency,
    // 获取配件型号
    async fetchAccessoryModel(){
      const res = await getAccessoryModel()
      const {code, data} = res
      if(code === 0){
        this.accessoryModelList = data
        console.log(this.accessoryModelList, 100)
        this.filters.modelId = this.accessoryModelList[this.currentModelIndex].id
        await this.fetchAccessoryAttribute()
      }
    },
    // 获取配件属性
    async fetchAccessoryAttribute(){
      const res = await getAccessoryAttribute(this.filters.modelId)
      const {code, data} = res
      if(code === 0){
        this.accessoryAttributeList = data.map(item=>{
          return{
            ...item,
            children:[{id: '', attributeId: item.id, name: 'All'}, ...item.children]
          }
        })
        this.accessoryAttributeList.forEach((item, index)=>{
          this.$set(this.checkedAttribute,index, item.children[0])
        })
        this.filters.attributeValueIds = this.checkedAttribute.filter(item=>item.id !== '').map(item=>item.id).join(',')
        console.log(this.filters, 114)
        console.log(this.accessoryAttributeList, 100)
      }
    },
    // 获取配件列表
    async fetchAccessoryList(){
      this.loading = true
      const {filters} = this
      const res = await getAccessoryList({...filters})
      const {code, data} = res
      if(code === 0){
        this.sourceAccessoryList = JSON.parse(JSON.stringify(data))
        if(this.sourceAccessoryList.length === this.displayedProductCount || this.sourceAccessoryList.length < this.displayedProductCount){
          this.showLoadMore = false
          this.accessoryList = this.sourceAccessoryList
        }else{
          this.showLoadMore = true
          this.accessoryList = this.sourceAccessoryList.slice(0, this.displayedProductCount)
        }
        this.loading = false
      }
    },
    // 切换型号
    handleChangeModel(item,index){
      this.filters = defaultFilters()
      this.currentModelIndex = index
      this.filters.modelId = item.id
      console.log(this.filters.modelId, 123)
      this.fetchAccessoryAttribute()
      this.fetchAccessoryList()
    },
    //点击下拉菜单
    handleCommand(index, e){
      console.log(index, e, 108)
      console.log(e.attributeId, 148)
      const existingIndex = this.checkedAttribute.findIndex(item => item.attributeId === e.attributeId);
      if (existingIndex !== -1) {
        // 如果存在，则替换
        this.$set(this.checkedAttribute, existingIndex, e)
      } else {
        this.$set(this.checkedAttribute,index, e)
      }
      console.log(this.checkedAttribute, 137)
      this.filters.attributeValueIds = this.checkedAttribute.filter(item=>item.id !== '').map(item=>item.id).join(',')
      console.log(this.filters, 148)
      this.fetchAccessoryList()
    },
    // 加载更多
    handleLoadMore(){
      this.displayedProductCount += 20
      this.accessoryList = this.sourceAccessoryList.slice(0, this.displayedProductCount)
      if(this.sourceAccessoryList.length === this.displayedProductCount || this.sourceAccessoryList.length < this.displayedProductCount) {
        this.showLoadMore = false
      }
    },
  // 配件详情
    toAccessoryDetail(id, type) {
      this.$refs.productDetail.handleOpen(id, type, 'accessory')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.custom-dropdown-menu {
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::v-deep .el-dropdown-menu__item{
    font-weight: 400;
    font-size: 16px;
    color: #151515;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    margin-bottom: 24px;
  }
}
.accessories-container{
  margin-top: 80px;
  .sticky{
    position: sticky;
    top: 80px;
    z-index: 10;
  }
  .title-container{
    width: 100%;
    height: 76px;
    background: #F8F8F8;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-left{
      display: flex;
      justify-content: space-between;
      .title-left-container{
        position: relative;
        margin-right: 36px;
        cursor: pointer;
        .text{
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          margin-bottom: 6px;
        }
        .description{
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 40px;
          height: 3px;
          background: #151515;
        }
        .active{
          color: #151515;
        }
      }
    }
    .title-right{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .attribute-container {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        margin-right: 40px;
        .title{
          margin-right: 8px;
          color: #888888;
        }
        ::v-deep .el-dropdown{
          font-weight: 400;
          font-size: 16px;
          color: #151515;
        }
      }
    }
  }
  .list-container{
    max-width: 1340px;
    margin-right: auto;
    margin-left: auto;
    padding: 37px 0;
    .device-right-container{
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 20px;
      justify-content: left;
      .device-info {
        width: 251px;
        background: #FFFFFF;
        border-radius: 5px;
        box-sizing: border-box;
        .image-container {
          width: 251px;
          height: 251px;
          box-sizing: border-box;
          cursor: pointer;
          img{
            width: 251px;
            height: 251px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
        .info{
          height: 84px;
          display: flex;
          justify-content: space-between;
          align-items: end;
          //height: calc(100% - 250px);
          padding: 18px 0;
          box-sizing: border-box;
          .info-left {
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            flex: 1;
            & > .title {
              width: 190px;
              white-space: nowrap; /* 确保文本在一行内显示 */
              overflow: hidden; /* 隐藏溢出的内容 */
              text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
            }
            .price{
              margin-top: 10px;
              font-weight: 400;
              font-size: 16px;
              color: #666666;
              line-height: 19px;
              text-align: left;
              font-style: normal;
              margin-top: 10px;
            }
          }
          .info-right{
            width: 40px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 50%;
            border: 1px solid #DBDBDB;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img{
              width: 18px;
              height: 18px;
            }
          }
        }

      }
    }
    .device-footer{
      width: 145px;
      height: 47px;
      border-radius: 47px;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 47px;
      text-align: center;
      font-style: normal;
      border: 1px solid #BEBEBE;
      margin: 50px auto;
    }
  }
  @media (max-height: 1080px){
    .list-container{
      //background: #1C7FF1;
      min-height: 32vw;
    }
  }
  @media (min-height: 1080px){
    .list-container{
      //background: #FF6912;
      min-height: 34vw;
    }
  }


}
</style>
