<template>
  <div class="login-container">
    <div class="header">
      <div class="left">
        <div class="logo" @click="toHome" style="cursor: pointer;">
          <img src="@/assets/index/logo.png" alt="">
        </div>
        <div class="line"></div>
        <div class="text">Login Account</div>
      </div>
      <div class="right">
        <span>Contact Number</span>
        <span class="phone">+6288123456789</span>
      </div>
    </div>
    <div class="head-bottom"></div>
    <div class="contain">
      <el-card class="box-card">
        <div class="card-head">Login</div>
        <div class="card-body">
          <el-form ref="ruleForm" :rules="loginRules" :model="currentForm">
            <el-form-item prop="email">
              <el-input placeholder="Email" type="text" v-model="currentForm.email" @keyup.enter.native="handleLogin" >
                <i slot="prefix" style="display: flex;align-items: center;">
                  <img
                      class="header-icon"
                      style="width:29px;height:29px"
                      src="@/assets/login/user_icon.png"
                      />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="Password" type="password" v-model="currentForm.password" show-password @keyup.enter.native="handleLogin" >
                <i slot="prefix" style="display: flex;align-items: center;">
                  <img
                      class="header-icon"
                      style="width:29px;height:29px"
                      src="@/assets/login/password_icon.png"
                  />
                </i>
              </el-input>
            </el-form-item>
            <div class="checked-box">
              <el-checkbox v-model="checked" @change="change">Remember this account</el-checkbox>
            </div>
            <div class="login-btn">
              <el-button size="large" @click="handleLogin" :loading="loading">Log In</el-button>
            </div>
            <div class="register-btn">
              <el-button @click="toRegister" >Create an account</el-button>
            </div>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>

</template>
<script>
const defaultCurrentForm =()=>({
  email: '',
  password: ''
})
export default {
  name: "LoginPage",
  data(){
    return {
      currentForm:defaultCurrentForm(),
      loginRules: {
        email: [
            { required: true, trigger: 'blur', message: 'Email is required' },
            {type:'email', trigger: 'blur', message: 'Please enter a valid email address' },
        ],
        password: [{ required: true, trigger: 'blur', message: 'Password is required' }]
      },
      checked: true,
      loading: false
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
        }
      },
      immediate: true
    }
  },
  created() {
    console.log(this.$store.getters.token, 94)
    const rememberedInfo = localStorage.getItem('rememberLoginInfo')
    if (rememberedInfo) {
      this.currentForm = JSON.parse(rememberedInfo)
    }
  },
  methods:{

    async handleLogin(){
      console.log(this, 78)
      this.loading = true
      const {$store, checked} = this
      this.$refs.ruleForm.validate(async valid => {
        if(valid){
          const res = await $store.dispatch('user/login', this.currentForm)
          if(res.code === 0){
            await $store.dispatch('user/getUserInfo')
            if (checked) {
              localStorage.setItem('rememberLoginInfo',JSON.stringify(this.currentForm))
            } else {
              localStorage.removeItem('rememberLoginInfo')
            }
            await this.$store.dispatch('cart/fetch')
            // await this.$router.push({ path: this.redirect || '/' })
            await this.$router.push({ path: '/' })
          }
          this.loading = false
        }else{
          this.loading = false
        }
      })

    },
    // 记住密码
    change(){
      console.log('点击记住密码', this.checked)
    },
    toRegister(){
      this.$router.push({
        path: '/register'
      })
    },
    async toHome(){
      this.$router.push({
        path: '/',
      })
    }
  }
}
</script>



<style scoped lang="scss">
.login-container{
  .header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px;
    box-sizing: border-box;
    .left{
      display: flex;
      align-items: center;

      .line{
        height: 36px;
        width: 0px;
        border: 1px solid #C0C0C0;
        margin: 0 30px;
      }
      .text{
        font-weight: 500;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
      }
    }

    .right{
      font-weight: 400;
      font-size: 14px;
      color: #888888;
      line-height: 16px;
      .phone{
        font-weight: 500;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        margin-left: 10px;
      }
    }

  }
  .head-bottom{
    width: 100%;
    height: 0px;
    border: 1px solid #DADADA;
  }
  .contain{
    .box-card{
      position: fixed;
      top: 225px;
      left: 50%;
      transform: translateX(-50%);
      width: 476px;
      height: 520px;
      border-radius: 12px;
      padding: 50px;
      box-sizing: border-box;
      .card-head{
        font-weight: 600;
        font-size: 36px;
        color: #151515;
        line-height: 42px;
        text-align: left;
        font-style: normal;
        margin-bottom: 36px;
      }
      .card-body{
        ::v-deep .el-input__inner{
          height: 48px;
          padding-left: 50px;
          font-weight: 400;
          font-family: MiSans, MiSans;
          font-weight: 400;
          font-size: 16px;
          //color: #BABABA;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }
        ::v-deep .el-input__prefix{
          width: 20px;
          height: 20px;
          margin: 12px;
        }
        ::v-deep .el-input__suffix{
          width: 22px;
          height: 22px;
          margin: 7px;
        }
        ::v-deep .el-input__clear{
           font-size: 20px;
         }
        ::v-deep .header-icon{
          width: 22px !important;
          height: 22px !important;
        }

        .checked-box{
          font-family: MiSans, MiSans;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 16px;
          text-align: left;
        }
        .login-btn{
          margin-top: 30px;
          width: 100%;
        }
        ::v-deep .el-button{
          width: 100%;
          height: 50px;
          background: #333333;
          font-family: MiSans, MiSans;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 19px;
          text-align: center;
          font-style: normal;
          border-radius: 11px 11px 11px 11px;
        }
        ::v-deep .register-btn{
          margin-top: 24px;
          .el-button{
            background: transparent !important;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
