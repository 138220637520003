<template>
  <div id="app">
    <div class="header" v-if="$route.path !== '/login' && $route.path !== '/' && $route.path !== '/register' && $route.path !== '/confirmOrder' && $route.path !== '/verify'">
      <div class="header-container">
        <div class="logo">
          <img src="@/assets/index/logo.png" alt="">
        </div>
        <div class="navbar">
          <ul>
            <li><router-link  :to="{ path: '/'}">HOME</router-link></li>
            <li><router-link  :to="{ path: '/accessories'}">ACCESSORIES</router-link></li>
            <li><router-link  :to="{ path: '/aboutUs'}">ABOUT US</router-link></li>
            <li><router-link  :to="{ path: '/contactUs'}">CONTACT US</router-link></li>
          </ul>
        </div>
        <div class="right">
          <div class="cart" @click="toShopCard">
            <el-badge :value="cartItemCount" class="badge">
              <i class="el-icon-goods"></i>
            </el-badge>
          </div>
          <el-popover
              placement="top-start"
              width="150"
              trigger="hover"
              :visible-arrow="false"
          >
            <div class="user-info" style="padding: 0 20px">
              <div class="user-action" v-if="hasToken" @click="handleLogout">Logout</div>
              <div class="user-action" v-else @click="toLogin">Login/Register</div>
              <div class="user-action" @click="toOrderHistory">Order History</div>
            </div>
            <i class="el-icon-user" slot="reference" style="cursor: pointer"></i>
          </el-popover>
        </div>
      </div>
    </div>
    <router-view />
    <ShopCard ref="shopCard"></ShopCard>
  </div>

</template>

<script>

export default {
  name: 'App',
  data(){
    return {
    }
  },
  computed: {
    hasToken() {
      return !!this.$store.getters.token
    },
    cartItemCount() {
      return this.hasToken ? this.$store.getters.cart.length : 0
    }
  },
  created() {
    if (this.$route.path === '/login') return
    if (this.$route.path === '/register') return
    if(this.hasToken){
      this.$store.dispatch('cart/fetch')
    }
  },
  // watch: {
  //   // 监听路由变化
  //   '$route'(to, from) {
  //     if (to.path !== '/login' && to.path !== '/register') {
  //       this.fetch() // 每次路由变化时请求数据
  //     }
  //   }
  // },
  methods:{
    toLogin() {
      this.$router.push({ path: '/login' })
    },
    async handleLogout() {
      await this.$store.dispatch('user/logout')
      await this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    toOrderHistory(){
      if(!this.hasToken){
        this.$router.push({ path: '/login' })
        return
      }
      this.$router.push({
        path: '/orderHistory'
      })
    },
    toShopCard(){
      if(!this.hasToken){
        this.$router.push({
          path: '/login'
        })
      }else{
        console.log('shopCard-----------', this.$refs.shopCard)
        this.$refs.shopCard.handleOpen()
      }

    },
  }
}
</script>

<style scoped lang="scss">
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  min-width: 1440px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header {
  position: fixed;
  width: calc(100vw - 17px);
  min-width: 1440px;
  top: 0;
  left: 0;
  height: 80px;
  z-index: 10;
  padding: 30px 24px;
  border-bottom: 1px solid #DADADA;
  background: #fff;
  box-sizing: border-box;
  .header-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
      width: 79px;
      height: 36px;
      & > img {
        width: 79px;
        height: 36px;
      }
    }
    .navbar{
      ul{
        display: flex;
        justify-content: space-between;
        list-style: none;
        li{
          margin-right: 64px;
        }
        a{
          text-decoration: none;
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 25px;
          text-align: left;
          font-style: normal;
          text-transform: none
        }
      }
    }
    .right{
      display: flex;
      justify-content: space-between;
      width: 65px;
      padding: 0 6px;
      font-size: 18px;
      .cart{
        cursor: pointer;
        ::v-deep .el-badge__content {
          background-color: #FF6912;
        }
      }
    }
  }
}
.el-popover, .el-popper {
  .user-info {
    .user-action {
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      font-family: Roboto, Roboto;
      font-style: normal;
    }
  }
}
</style>
