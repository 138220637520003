import Vue from "vue"
import VueRouter from 'vue-router'
import Login from "../views/login"
import Index from "../views/index"
import Accessories from "../views/accessories"
import AboutUs from "../views/aboutUs"
import ContactUs from "../views/contactUs"
import Register from "@/views/register/index.vue";
import OrderHistory from "@/views/order/orderHistory/index.vue";
import OrderDetail from "@/views/order/orderDetail/index.vue";
import AccessoriesSearch from "@/views/accessoriesSearch/index.vue";
import ConfirmOrder from "@/views/order/confirmOrder/index.vue";
import VerifyPage from "@/views/register/verifyPage/index.vue";
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/login',
            component: Login,
        },
        {
            path: '/register',
            component: Register,
        },
        {
            path: '/verify',
            component: VerifyPage,
        },
        {
            path: '/',
            component: Index,
        },
        {
            path: '/accessories',
            component: Accessories,
        },
        {
            path: '/search',
            component: AccessoriesSearch,
        },
        {
            path: '/aboutUs',
            component: AboutUs,
        },
        {
            path: '/contactUs',
            component: ContactUs,
        },
        {
            path: '/orderHistory',
            component: OrderHistory,
        },
        {
            path: '/orderDetail',
            component: OrderDetail,
        },
        {
            path: '/confirmOrder',
            component: ConfirmOrder,
        }
    ]
})

export default router
