var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.dialogLoading,
          expression: "loading.dialogLoading",
        },
      ],
      attrs: {
        visible: _vm.visible,
        width: "1140px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.saveLoading,
              expression: "loading.saveLoading",
            },
          ],
          staticClass: "detail-container",
        },
        [
          _c("div", { staticClass: "top" }, [
            _vm.productDetail.images && _vm.productDetail.images.length > 0
              ? _c("div", { staticClass: "left" }, [
                  _c(
                    "div",
                    { staticClass: "inner-left" },
                    _vm._l(_vm.productDetail.images, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "img-container",
                          class: { active: _vm.currentBannerIndex === index },
                          on: {
                            click: function ($event) {
                              return _vm.handleChangeBanner(item, index)
                            },
                          },
                        },
                        [
                          item.type === "video"
                            ? _c(
                                "video",
                                { attrs: { width: "72", height: "72" } },
                                [
                                  _c("source", {
                                    attrs: { src: item.url, type: "video/mp4" },
                                  }),
                                  _vm._v(" 您的浏览器不支持视频标签。 "),
                                ]
                              )
                            : _c("el-image", {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value:
                                      _vm.loading.imgLoading.smallImgLoading,
                                    expression:
                                      "loading.imgLoading.smallImgLoading",
                                  },
                                ],
                                staticStyle: { width: "72px", height: "72px" },
                                attrs: { src: item.url, fit: "cover" },
                                on: {
                                  load: () =>
                                    (_vm.loading.imgLoading.smallImgLoading = false),
                                },
                              }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "inner-right" },
                    [
                      _vm.bannerType === "video"
                        ? _c(
                            "video",
                            {
                              attrs: {
                                width: "400",
                                height: "400",
                                autoplay: "",
                                muted: "",
                                controls: "",
                              },
                              domProps: { muted: true },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src: _vm.bannerImageUrl,
                                  type: "video/mp4",
                                },
                              }),
                              _vm._v(" 您的浏览器不支持视频标签。 "),
                            ]
                          )
                        : _c("el-image", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.imgLoading.bigImgLoading,
                                expression: "loading.imgLoading.bigImgLoading",
                              },
                            ],
                            staticStyle: { width: "400px", height: "400px" },
                            attrs: { src: _vm.bannerImageUrl, fit: "cover" },
                            on: {
                              load: () =>
                                (_vm.loading.imgLoading.bigImgLoading = false),
                            },
                          }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "right" }, [
              _c("div", { staticClass: "right-title" }, [
                _c("div", { staticClass: "right-title-top" }, [
                  _vm._v(_vm._s(_vm.productDetail.name)),
                ]),
                _c("div", { staticClass: "right-title-center" }, [
                  _vm.productDetail.models &&
                  Object.keys(_vm.productDetail.models).length > 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            Object.values(_vm.productDetail.models).join(", ")
                          )
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "right-title-foot price" }, [
                  !_vm.checkedAttributesStock ||
                  Object.keys(_vm.checkedAttributesStock).length === 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.currency(_vm.productDetail.discountPrice))
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.currency(
                              _vm.checkedAttributesStock.discountPrice
                            )
                          )
                        ),
                      ]),
                  _vm.productDetail.discountPrice !== _vm.productDetail.price ||
                  _vm.checkedAttributesStock.discountPrice !==
                    _vm.checkedAttributesStock.price
                    ? _c("span", [
                        !_vm.checkedAttributesStock ||
                        Object.keys(_vm.checkedAttributesStock).length === 0
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-left": "16px",
                                  color: "#BABABA",
                                  "font-weight": "400",
                                  "font-size": "16px",
                                  "text-decoration": "line-through",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.currency(_vm.productDetail.price))
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-left": "16px",
                                  color: "#BABABA",
                                  "font-weight": "400",
                                  "font-size": "16px",
                                  "text-decoration": "line-through",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.currency(
                                      _vm.checkedAttributesStock.price
                                    )
                                  )
                                ),
                              ]
                            ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm.productDetail.stocks && _vm.productDetail.stocks.length > 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.productDetail.attributes,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "right-attributes" },
                          [
                            _c("div", { staticClass: "attributes-title" }, [
                              _vm._v(_vm._s(item.name) + ":"),
                            ]),
                            _vm._l(item.values, function (value, valueIndex) {
                              return _c(
                                "el-tag",
                                {
                                  key: value.id,
                                  class: {
                                    tagActive:
                                      item.currentTagIndex === valueIndex,
                                  },
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { type: "info", effect: "plain" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkedAttributeTag(
                                        item,
                                        value,
                                        index,
                                        valueIndex
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(value.name))]
                              )
                            }),
                          ],
                          2
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _c("div", { staticClass: "btn-container" }, [
                _c("div", { staticClass: "right-num" }, [
                  _c("div", { staticClass: "right-num-title" }, [
                    _vm._v("Purchase quantity:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "right-num-number" },
                    [
                      _c("el-input-number", {
                        attrs: { precision: 0, min: 1 },
                        model: {
                          value: _vm.selectedQuantity,
                          callback: function ($$v) {
                            _vm.selectedQuantity = $$v
                          },
                          expression: "selectedQuantity",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "right-btn",
                    class: { disabled: _vm.isDisabled },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "submit-btn cart",
                        attrs: { disabled: _vm.isSaving },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddToCart("toCart")
                          },
                        },
                      },
                      [_vm._v("Add To Cart")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "submit-btn",
                        attrs: { disabled: _vm.isSaving },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddToCart("toOrder")
                          },
                        },
                      },
                      [_vm._v("Order Now")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.productDetail.desc
            ? _c("div", { staticClass: "foot" }, [
                _c("div", { staticClass: "foot-title" }, [
                  _vm._v(" DESCRIPTION "),
                  _c("div", { staticClass: "icon" }),
                ]),
                _c("div", {
                  staticClass: "foot-text",
                  domProps: { innerHTML: _vm._s(_vm.productDetail.desc) },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }