<template>
  <el-drawer
      :before-close="handleClose"
      :visible.sync="visible"
      direction="rtl"
      :size="700"
  >
    <div v-loading="loading" class="container">
      <div class="title">Shopping Cart</div>
      <div class="detail">
        <div v-for="(item, index) in list" :key="item.id" class="detail-container" :class="{ disabled: item.disabled}">
          <div class="banner">
            <el-image
                :src="item.cropThumbnail"
                fit="fill"
                style="width: 76px; height: 76px;margin-right: 20px"
            >
            </el-image>
          </div>
          <div class="tips" v-if="item.originalProductId !== item.productId">This product has been taken down</div>
          <div class="product-title">
            <div class="name">{{ item.productName }}</div>
            <div class="sku">{{item.productStockSkuValue}}</div>
          </div>
          <div class="price" v-if="item.productStockDiscountPrice">{{ currency(item.productStockDiscountPrice) }}</div>
          <div class="price" v-else>{{ currency(item.productDiscountPrice) }}</div>
          <div class="detail-number">
            <el-input-number v-model="item.quantity" size="small" :precision="0" :min="1" step-strictly @change="(currentValue)=>handleChange(item, currentValue)"></el-input-number>
          </div>
          <div class="detail-right">
            <div class="amount">{{ currency(itemTotalPrices[index]) }}</div>
            <div class="detail-action">
              <i class="el-icon-delete" :style="{color: item.disabled ? '#212121' : '#666'}" @click="handleRemove(item)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="subtotal" v-if="list.length > 0">
        <div class="sub-title">Subtotal:</div>
        <div class="total">{{currency(subTotalPrice)}}</div>
      </div>
      <div class="btn">
        <el-button class="continue" @click="handleClose">Continue purchasing</el-button>
        <el-button class="continue order" @click="toOrderHistory">Order Now</el-button>
      </div>
    </div>

  </el-drawer>
</template>

<script>
import { currency } from '@/utils/util'
import { edit, remove} from "@/api/cart";
import {ORDER_SOURCE} from "@/tool/enum"

export default {
  name: "ShopCard",
  data() {
    return {
      visible: false,
      loading: false,
      ORDER_SOURCE,
      timer: null
    }
  },
  computed: {
    // 计算单个商品价格
    itemTotalPrices() {
      return this.list.map(item => {
        const price = item.productStockDiscountPrice ? item.productStockDiscountPrice : item.productDiscountPrice
        return item.quantity * price
      })
    },
    list() {
      return this.$store.getters.cart.map(item => {
        console.log(item.productStatus === 0,item.productStockStatus === 0,item.productStockId !== item.originalProductStockId,item.originalProductId !== item.productId, 75)
        return {
          ...item,
          productStockSkuValue:item.productStockSkuValue && JSON.parse(item.productStockSkuValue).join(' / '),
          disabled: item.productStatus === 0 || item.productStockStatus === 0 || item.productStockId !== item.originalProductStockId || item.originalProductId !== item.productId
        }
      })
    },
    subTotalPrice() {
      const { cart } =  this.$store.getters
      return cart.reduce((total, item) => {
        const price = item.productStockDiscountPrice ? item.productStockDiscountPrice : item.productDiscountPrice
        return total + (item.quantity * price)
      }, 0)
    },
  },
  methods: {
    currency,
    // 数量
    async handleChange(item, currentValue) {
      console.log(93, this.timer, currentValue)
      if (this.timer) clearTimeout(this.timer)
      // 需要增加防抖效果
      this.timer = setTimeout(async () => {
        const {id, productId, productStockId} = item
        const data = {id, productId, quantity: item.quantity, productStockId}
        const res = await edit(data)
        const {code} = res
        if (code === 0) {
          await this.$store.dispatch('cart/fetch')
        }
      }, 300)
    },

    // 删除
    handleRemove({id}) {
      this.$confirm('Are you sure to delete this product?', {
        confirmButtonText: 'yes',
        cancelButtonText: 'no',
        type: 'warning'
      })
          .then(async() => {
            const res = await remove(id)
            const {code} = res
            if (code === 0) {
              this.$message.success('Deleted!')
              await this.$store.dispatch('cart/fetch')
            }
          })
          .catch(() => {
          })
    },
    handleOpen() {
      this.visible = true
    },
    handleClose() {
      this.visible = false;
    },
    async toOrderHistory() {
      const exist = this.list.some(item => item.disabled === true)
      console.log(exist, 142)
      if(exist){
        this.$message.warning('Please delete the products that have been taken down first!')
        return
      }
      this.$confirm('Are you sure to place the order now?', {
        confirmButtonText: 'yes',
        cancelButtonText: 'no',
        type: 'warning'
      })
          .then(async() => {
            this.visible = false
            localStorage.setItem('source', ORDER_SOURCE.Cart)
            this.$router.push({
              path: '/confirmOrder'
            })
          })
          .catch(() => {
          })

    }
  }
}
</script>


<style lang="scss" scoped>
::v-deep .el-drawer__header{
  margin-bottom: 0;
}
.container {
  padding: 0 30px;
  .title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    color: #151515;
    text-align: left;
  }

  .detail::-webkit-scrollbar {
    display: none;
  }

  .detail {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0 25px 0;
      border-bottom: 1px solid #DADADA;
      box-sizing: border-box;
      & > .banner {
        width: 76px;
        height: 76px;
        & > .el-image {
          width: 76px;
          height: 76px;
          & > img {
            width: 76px;
            height: 76px;
          }
        }
      }
      .product-title {
        width: 200px;
        height: 76px;
        padding-top: 5px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        .name {
          font-weight: 500;
          font-size: 16px;
          color: #151515;
          line-height: 19px;
          margin-bottom: 10px;
          text-align: left;
        }
        .sku {
          font-weight: 400;
          font-size: 14px;
          color: #888888;
          line-height: 16px;
          text-align: left;
        }
      }

      .price {
        width: 60px;
        height: 76px;
        padding-top: 5px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 16px;
        text-align: left;
      }
      .detail-number {
        width: 130px;
        height: 76px;
        padding-top: 5px;
        box-sizing: border-box;
      }
      .detail-right {
        width: 100px;
        height: 76px;
        padding-top: 5px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: end;
        .amount {
          margin-bottom: 24px;
        }
        .detail-action {
          cursor: pointer;
          color: #999999;
          .el-icon-delete{
            pointer-events: auto !important;
          }
        }

      }

    }
  }
  @media (max-height: 900px){
    .detail{
      max-height: 500px;
    }
  }

  @media (min-height: 900px) and (max-height: 1000px){
    .detail{
      max-height: 600px;
    }
  }

  @media (min-height: 1000px){
    .detail{
      max-height: 800px;
    }
  }



  .subtotal {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 30px 0 0;

    .sub-title {
      width: 90px;
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 19px;
      text-align: left;
      font-style: normal;
    }

    .total {
      width: 160px;
      font-weight: 500;
      font-size: 20px;
      color: #151515;
      line-height: 23px;
      text-align: right;
      font-style: normal;
    }
  }

  .btn {
    position: fixed;
    bottom: 40px;
    right: 40px;

    .continue {
      width: 230px;
      height: 53px;
      text-align: center;
      border-radius: 11px 11px 11px 11px;
      border: 1px solid #151515;
      font-weight: 400;
      font-size: 18px;
      color: #151515;
    }

    .order {
      margin-left: 30px;
      background: #151515;
      color: #FFFFFF;
    }
  }
}
</style>
