/**
 * 格式化 vue 数据，用于 console.log，去除...
 * @param data
 * @returns {any}
 */
export const f = (data) => {
  return JSON.parse(JSON.stringify(data))
}

/**
 * 过滤空属性
 * @param params
 * @returns {{}|*}
 */
export const filter = (params, filterBlank = true) => {
  try {
    if (params instanceof FormData) return params
    if (typeof (params) === 'object') {
      const data = {}
      for (const key in params) {
        if (params[key] === undefined) continue
        if (params[key] === null) continue
        if (filterBlank && params[key] === '') continue
        data[key] = params[key]
      }
      return data
    }
    return params
  } catch (e) {
    return params
  }
}


export const currencyTransform = (fen) => {
  return currency((fen / 100).toFixed(2) + '', null, 2)
}

export const percentageTransform = (num) => {
  return (num * 100).toFixed(2) + '%'
}

const digitsRE = /(\d{3})(?=\d)/g

/**
 * value  金额
 * currency 货币符号
 * decimals  保留位数
 */
export const currency = (value, currency, decimals) => {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  currency = currency != null ? currency : '$'
  decimals = decimals != null ? decimals : 2
  var stringified = Math.abs(value).toFixed(decimals)
  var _int = decimals
    ? stringified.slice(0, -1 - decimals)
    : stringified
  var i = _int.length % 3
  var head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : ''
  var _float = decimals
    ? stringified.slice(-1 - decimals)
    : ''
  var sign = value < 0 ? '-' : ''
  return sign + currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
}

