import { cartList } from "@/api/cart";

const state = {
    list: [],
}
const mutations = {
    setCart(state, data) {
        state.list = data
    }
}

const actions = {
    async fetch(context, data) {
        console.log('fetch cart')
        const res = await cartList(data)
        if ( res.code === 0 ) {
            context.commit('setCart', res.data)
        } else {
            console.log(res.message)
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
