var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "head-bottom" }),
    _c(
      "div",
      { staticClass: "contain" },
      [
        _c("el-card", { staticClass: "box-card" }, [
          _c("div", { staticClass: "card-head" }, [
            _vm._v("Create an Account"),
          ]),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { rules: _vm.rules, model: _vm.currentForm },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "firstName" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "First Name",
                            type: "text",
                            maxlength: 200,
                          },
                          model: {
                            value: _vm.currentForm.firstName,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentForm, "firstName", $$v)
                            },
                            expression: "currentForm.firstName",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("First Name"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "lastName" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "Last Name",
                            type: "text",
                            maxlength: 200,
                          },
                          model: {
                            value: _vm.currentForm.lastName,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentForm, "lastName", $$v)
                            },
                            expression: "currentForm.lastName",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Last Name"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "Email", type: "text" },
                          model: {
                            value: _vm.currentForm.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentForm, "email", $$v)
                            },
                            expression: "currentForm.email",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Email"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "confirm-password",
                          attrs: {
                            placeholder: "Password",
                            type: "password",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.currentForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentForm, "password", $$v)
                            },
                            expression: "currentForm.password",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Password"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "confirmPassword" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "confirm-password",
                          attrs: {
                            placeholder: "ConfirmPassword",
                            type: "password",
                            "show-password": "",
                          },
                          model: {
                            value: _vm.currentForm.confirmPassword,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentForm, "confirmPassword", $$v)
                            },
                            expression: "currentForm.confirmPassword",
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("ConfirmPassword"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "login-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "large", loading: _vm.loading },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Create account")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: { src: require("@/assets/index/logo.png"), alt: "" },
          }),
        ]),
        _c("div", { staticClass: "line" }),
        _c("div", { staticClass: "text" }, [_vm._v("Create an Account")]),
      ]),
      _c("div", { staticClass: "right" }, [
        _c("span", [_vm._v("Contact Number")]),
        _c("span", { staticClass: "phone" }, [_vm._v("+6288123456789")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }