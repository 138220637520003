<template>
  <div class="contact-container">
    <div class="container">
      <div class="title">Contact Us</div>
      <div class="content-container">
        <div class="left">
<!--          <div class="map-container"></div>-->
          <baidu-map
              class="map-container"
              :center="{lng: 103.8198, lat: 1.3521}"
              :zoom="12"
          >
            <bm-marker :position="{lng: 103.8198, lat: 1.3521}">
              <bm-info-window :content="'新加坡总部位置'"></bm-info-window>
            </bm-marker>
          </baidu-map>
          <div class="phone">
            <div class="tip">Contact Number</div>
            <div class="number">+628123456789</div>
          </div>
          <div class="phone">
            <div class="tip">Social media</div>
            <div class="number pic-container">
              <img src="@/assets/index/f-word.png" alt="">
              <img src="@/assets/index/camera.png" alt="">
              <img src="@/assets/index/bird.png" alt="">
              <img src="@/assets/index/wechat.png" alt="">
              <img src="@/assets/index/phone.png" alt="">
            </div>
          </div>
        </div>
        <div class="right">
          <el-form :inline="true" class="demo-form-inline" ref="ruleForm" :rules="rules" :model="currentForm">
            <div class="contact-form-row">
              <div>
                <div class="input-title">First name</div>
                <el-form-item prop="firstName">
                  <el-input placeholder="First name" type="text" v-model="currentForm.firstName"></el-input>
                </el-form-item>
              </div>
              <div>
                <div class="input-title">Last name</div>
                <el-form-item prop="lastName">
                  <el-input placeholder="Last name" type="text" v-model="currentForm.lastName"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="contact-form-row">
              <div>
                <div class="input-title">Email</div>
                <el-form-item prop="email">
                  <el-input placeholder="Email" type="text" v-model="currentForm.email"></el-input>
                </el-form-item>
              </div>
              <div>
                <div class="input-title">Phone number</div>
                <el-form-item>
                  <el-input placeholder="Phone number" type="text" v-model="currentForm.phone"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="contact-form-row">
              <div>
                <div class="input-title">Message</div>
                <el-form-item>
                  <el-input placeholder="Message" type="textarea" :autosize="{ minRows: 4}" v-model="currentForm.message"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="contact-form-row"  style="justify-content: center;">
              <div>
                <el-button class="submit-btn" @click="save">Send message</el-button>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <Contact></Contact>
  </div>
</template>

<script>
import Contact from "@/components/contact.vue";
import {submit} from "@/api/contactUs";
const defaultCurrentForm =()=>({
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: ''
})
export default {
  name: 'ContactUs' ,
  components: {Contact},
  data(){
    return {
      currentForm: defaultCurrentForm(),
      rules: {
        firstName: [
          { required: true, trigger: 'blur', message: 'FirstName is required' },
        ],
        lastName: [
          { required: true, trigger: 'blur', message: 'LastName is required' },
        ],
        email: [
          { required: true, trigger: 'blur', message: 'Email is required' },
          {type:'email', trigger: 'blur', message: 'Please enter a valid email address' },
        ],
      },
    }
  },
  methods:{
    async save(){
      console.log(this.currentForm, 105)
      this.$refs.ruleForm.validate(async valid => {
        if(valid){
          this.$confirm('Are you sure to send the message?', {
            confirmButtonText: 'yes',
            cancelButtonText: 'no',
            type: 'warning'
          })
              .then(async() => {
                const res = await submit({...this.currentForm})
                const {code} = res
                if(code === 0){
                  this.$message.success('Sent Message successfully!')
                  this.currentForm = defaultCurrentForm()
                }
              })
              .catch(() => {
              })
        }
      })


    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.contact-container{
  .container {
    width: 1080px;
    margin: 0 auto;
    .title {
      font-weight: 600;
      font-size: 30px;
      color: #151515;
      margin-top: 150px;
      text-align: left;
      padding-bottom: 24px;
      border-bottom: 1px solid #DADADA;
      margin-bottom: 34px;
    }
    .content-container{
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      .left{
        padding-top: 15px;
        .map-container{
          width: 420px;
          height: 248px;
        }
        .phone {
          display: flex;
          justify-content: start;
          margin: 30px 0;
          .tip{
            font-weight: 400;
            font-size: 14px;
            color: #151515;
            width:153px;
            height: 36px;
            line-height: 36px;
            vertical-align: middle;
            text-align: left;
          }
          .number{
            font-weight: 500;
            font-size: 18px;
            color: #151515;
            text-align: left;
            height: 36px;
            line-height: 36px;
            vertical-align: middle;
          }
          .pic-container{
            img{
              width: 36px;
              margin-right: 16px;
            }
          }
        }
      }
      .right{
        width: 547px;
        margin-right: -10px;
        text-align: left;
        .contact-form-row {
          display: flex;
          justify-content: space-between;
          .submit-btn {
            width: 172px;
            height: 47px;
            background: #333333;
            border-radius: 11px;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
          }
          .input-title{
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            margin-bottom: 16px;
          }
          ::v-deep .el-input__inner{
            width: 250px;
            font-family: Roboto, Roboto;
          }
          ::v-deep .el-textarea__inner{
            width: 547px;
            font-family: Roboto, Roboto;
          }
        }
      }
    }
  }
  @media (max-height: 1080px){
    .container{
      //background: #1C7FF1;
      min-height: 34vw;
    }
  }
  @media (min-height: 1080px){
    .container{
      //background: #FF6912;
      min-height: 34vw;
    }
  }
}
</style>
