var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details-container" },
    [
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c("div", { staticClass: "table-title" }, [
          _c("div", { staticClass: "Number" }, [
            _c("div", { staticClass: "inner-title" }, [
              _vm._v("Order Number:"),
            ]),
            _c(
              "div",
              { staticClass: "inner-content" },
              [
                _c("div", [_vm._v(_vm._s(_vm.detailData.orderNo))]),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.detailData.orderNo,
                        expression: "detailData.orderNo",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.handleCopy,
                        expression: "handleCopy",
                        arg: "success",
                      },
                    ],
                    staticClass: "copy-btn",
                  },
                  [_vm._v("Copy")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "Number" }, [
            _c("div", { staticClass: "inner-title" }, [_vm._v("Order Time:")]),
            _c("div", { staticClass: "inner-content" }, [
              _vm._v(_vm._s(_vm.detailData.createTime)),
            ]),
          ]),
          _c("div", { staticClass: "Number" }, [
            _c("div", { staticClass: "inner-title" }, [
              _vm._v("Order status:"),
            ]),
            _vm.detailData.deliveryTrackingNo === null
              ? _c("div", [
                  _vm.detailData.status === 0
                    ? _c(
                        "div",
                        {
                          staticClass: "inner-content",
                          staticStyle: { color: "#FF6912" },
                        },
                        [_vm._v("unconfirmed")]
                      )
                    : _vm.detailData.status === 1
                    ? _c("div", { staticClass: "inner-content" }, [
                        _vm._v("Confirmed"),
                      ])
                    : _vm._e(),
                ])
              : _vm.detailData.deliveryTrackingNo !== null
              ? _c("div", { staticClass: "inner-content" }, [
                  _vm._v("Delivered"),
                ])
              : _vm._e(),
          ]),
          _vm.detailData.deliveryTrackingNo !== null
            ? _c(
                "div",
                { staticClass: "Number", staticStyle: { "margin-right": "0" } },
                [
                  _c("div", { staticClass: "inner-title" }, [
                    _vm._v("Tracking number:"),
                  ]),
                  _c("div", { staticClass: "inner-content" }, [
                    _vm._v(_vm._s(_vm.detailData.deliveryTrackingNo)),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm.orderVoLists.length > 0
          ? _c(
              "div",
              { staticClass: "detail" },
              _vm._l(_vm.orderVoLists, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "detail-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "detail-left" },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "80px",
                            height: "80px",
                            "margin-right": "40px",
                          },
                          attrs: { src: item.thumbnail, fit: "cover" },
                        }),
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.productName)),
                          ]),
                          _c("div", { staticClass: "price" }, [
                            _vm._v(_vm._s(item.productStockSkuValue)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "detail-right" }, [
                      _c("div", { staticClass: "price" }, [
                        _vm._v(_vm._s(_vm.currency(item.price))),
                      ]),
                      _c("div", { staticClass: "quantity" }, [
                        _vm._v("x" + _vm._s(item.quantity)),
                      ]),
                      _c("div", { staticClass: "amount" }, [
                        _vm._v(_vm._s(_vm.currency(item.totalPrice))),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "empty" },
              [
                _c("el-empty", {
                  attrs: {
                    description:
                      "No product information available at the moment",
                  },
                }),
              ],
              1
            ),
        _vm._m(1),
        _c("div", { staticClass: "subtotal-container" }, [
          _c("div", { staticClass: "subtotal" }, [
            _c("div", { staticClass: "sub-title" }, [_vm._v("Subtotal")]),
            _c("div", { staticClass: "price" }, [
              _vm._v(_vm._s(_vm.currency(_vm.detailData.totalAmount))),
            ]),
          ]),
        ]),
      ]),
      _c("ContactUs"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "title-left" }, [_vm._v("Order details")]),
      _c("div", { staticClass: "title-right" }, [
        _c("a", { attrs: { href: "/orderHistory" } }, [_vm._v("Back to list")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "payment-container" }, [
      _c("div", { staticClass: "payment-title" }, [_vm._v("Payment method")]),
      _c("div", { staticClass: "info-container" }, [
        _c("div", { staticClass: "info-inner" }, [
          _c("div", { staticClass: "inner-title" }, [_vm._v("Account Name:")]),
          _c("div", { staticClass: "inner-content" }, [
            _vm._v("Antony Edwords"),
          ]),
        ]),
        _c("div", { staticClass: "info-inner" }, [
          _c("div", { staticClass: "inner-title" }, [_vm._v("bank:")]),
          _c("div", { staticClass: "inner-content" }, [
            _vm._v("DBS Debit Card"),
          ]),
        ]),
        _c("div", { staticClass: "info-inner" }, [
          _c("div", { staticClass: "inner-title" }, [_vm._v("Card number:")]),
          _c("div", { staticClass: "inner-content" }, [
            _vm._v("4539 6876 5432 1098"),
          ]),
        ]),
        _c("div", { staticClass: "info-inner" }, [
          _c("div", { staticClass: "inner-title" }, [_vm._v("swift code：")]),
          _c("div", { staticClass: "inner-content" }, [_vm._v("W1695242884")]),
        ]),
      ]),
      _c("div", { staticClass: "tips" }, [
        _c("img", {
          attrs: { src: require("@/assets/order/tip.png"), alt: "" },
        }),
        _c("span", { staticClass: "tips-text" }, [
          _vm._v(
            "When transferring funds to the receiving account, please provide your order number for our backend verification. Thank you for your cooperation."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }