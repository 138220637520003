import request from '@/utils/request'

export function getOrderList(params) {
    return request({
        url: '/order/list/pagination',
        method: 'get',
        params
    })
}

// 订单详情
export function getOrderDetail(id) {
    return request({
        url: `/order/${id}`,
        method: 'get'
    })
}

// 从产品创建订单
export function orderFromProduct(data) {
    return request({
        url: '/order/product',
        method: 'post',
        data
    })
}

// 统一下单
export function order(data) {
    return request({
        url: '/order',
        method: 'post',
        data
    })
}
