<template>
  <div class="login-container">
    <div class="header">
      <div class="left">
        <div class="logo">
          <img src="@/assets/index/logo.png" alt="">
        </div>
        <div class="line"></div>
        <div class="text">Create an Account</div>
      </div>
      <div class="right">
        <span>Contact Number</span>
        <span class="phone">+6288123456789</span>
      </div>
    </div>
    <div class="head-bottom"></div>
    <div class="contain">
      <el-card class="box-card">
        <div class="card-head">Create an Account</div>
        <div class="card-body">
          <el-form ref="ruleForm" :rules="rules" :model="currentForm">
            <el-form-item prop="firstName">
              <el-input placeholder="First Name" type="text" :maxlength="200" v-model="currentForm.firstName" >
                <i slot="prefix" style="display: flex;align-items: center;">
                  <span class="label">First Name</span>
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="lastName">
              <el-input placeholder="Last Name" type="text" :maxlength="200" v-model="currentForm.lastName" >
                <i slot="prefix" style="display: flex;align-items: center;">
                  <span class="label">Last Name</span>
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="email">
                  <el-input placeholder="Email" type="text" v-model="currentForm.email" >
                <i slot="prefix" style="display: flex;align-items: center;">
                  <span class="label">Email</span>
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="Password" type="password" v-model="currentForm.password" show-password class="confirm-password">
                <i slot="prefix" style="display: flex;align-items: center;">
                  <span class="label">Password</span>
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input placeholder="ConfirmPassword" type="password" v-model="currentForm.confirmPassword" show-password class="confirm-password">
                <i slot="prefix" style="display: flex;align-items: center;">
                  <span class="label">ConfirmPassword</span>
                </i>
              </el-input>
            </el-form-item>
            <div class="login-btn">
              <el-button size="large" @click="save" :loading="loading">Create account</el-button>
            </div>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>

</template>
<script>
import {register} from "@/api/login";
const defaultCurrentForm =()=>({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: ''
})
export default {
  name: "RegisterPage",
  data(){
    return {
      currentForm:defaultCurrentForm(),
      rules: {
        firstName: [{ required: true, trigger: 'blur', message: 'FirstName is required' }],
        lastName: [{ required: true, trigger: 'blur', message: 'LastName is required' }],
        email: [
          { required: true, trigger: 'blur', message: 'Email is required' },
          {type:'email', trigger: 'blur', message: 'Please enter a valid email address' },
        ],
        password: [{ required: true, trigger: 'blur', message: 'Password is required' }],
        confirmPassword: [{ required: true, trigger: 'blur', message: 'ConfirmPassword is required' }]
      },
      loading: false
    }
  },
  methods:{
    save(){
      this.loading = true
      const {password, confirmPassword} = this.currentForm
      if(confirmPassword !== password){
        this.$message.error('Passwords do not match')
        return
      }
      this.$refs.ruleForm.validate(async valid => {
        if(valid){
          const res = await register({...this.currentForm})
          console.log(res, 81)
          const {code} = res
          if(code === 0){
            this.$message.success('The verification email has been sent. Please go to your email for account verification.')
            await this.$router.push({
              path: '/login'
            })
          }
          this.loading = false
        }else{
          this.loading = false
        }
      })


    }
  }
}
</script>



<style scoped lang="scss">
.login-container{
  .header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px;
    box-sizing: border-box;
    .left{
      display: flex;
      align-items: center;

      .line{
        height: 36px;
        width: 0px;
        border: 1px solid #C0C0C0;
        margin: 0 30px;
      }
      .text{
        font-weight: 500;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
      }
    }

    .right{
      font-weight: 400;
      font-size: 14px;
      color: #888888;
      line-height: 16px;
      .phone{
        font-weight: 500;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        margin-left: 10px;
      }
    }

  }
  .head-bottom{
    width: 100%;
    height: 0px;
    border: 1px solid #DADADA;
  }
  .contain{
    .box-card{
      position: fixed;
      top: 225px;
      left: 50%;
      transform: translateX(-50%);
      width: 476px;
      //height: 520px;
      border-radius: 12px;
      padding: 50px;
      box-sizing: border-box;
      .card-head{
        font-weight: 600;
        font-size: 36px;
        color: #151515;
        line-height: 42px;
        text-align: left;
        font-style: normal;
        margin-bottom: 36px;
      }
      .card-body{
        ::v-deep .el-input__inner{
          height: 48px;
          line-height: 48px;
          padding-left: 168px;
          font-weight: 400;
          font-size: 14px;

        }
        ::v-deep .el-input__prefix{
          height: 29px;
          line-height: 29px;
          margin: 10px 14px;
          .label{
            font-family: MiSans, MiSans;
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            font-style: normal;
          }
        }
        ::v-deep .el-input__suffix{
          width: 22px;
          height: 22px;
          margin: 3px 18px;
        }
        .confirm-password{
          ::v-deep .el-input__suffix{
            margin: 5px;
          }
        }
        ::v-deep .el-input__clear{
           font-size: 21px;
         }

        .checked-box{
          text-align: left;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
        }
        .login-btn{
          margin-top: 40px;
          width: 100%;
        }
        ::v-deep .el-button{
          width: 100%;
          height: 50px;
          background: #333333;
          font-family: MiSans, MiSans;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 19px;
          text-align: center;
          border-radius: 11px 11px 11px 11px;
        }
        ::v-deep .register-btn{
          margin-top: 24px;
          .el-button{
            background: transparent !important;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
