<template>
  <div class="accessories-container">
    <div class="search" v-if="isSearch">
      <div class="search-container">
        <el-input
            placeholder="Search for accessories"
            prefix-icon="el-icon-search"
            v-model="filters.name"
            @change="handleInput"
        >
        </el-input>
      </div>
      <div class="search-result">
        <div class="search-result-left">Found {{resultNum}} results</div>
        <div class="search-result-right">
          <div class="title">brand:</div>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{currentBrandName}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
              <el-dropdown-item v-for="item in brandList" :key="item.id" :command="item">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <ProductIndex ref="productList" :is-search="isSearch" :currentBrandId="currentBrandId" @update-resultNum="updateResultNum" @update-brandIndex="updateBrandIndex"></ProductIndex>
    <Contact></Contact>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
import ProductIndex from "@/components/product/product.vue";
import {getBrandList, getCategoryList} from "@/api/device";
import {all} from "axios";
export default {
  name: 'ACCESSORIES',
  components: { ProductIndex, Contact},
  data(){
    return {
      brandList: [],
      currentBrandIndex: 0,
      categoryList: [],
      loadingCategory: false,
      isSearch: false,
      currentBrandName: '',
      currentBrandId: '',
      filters: {
        name: ''
      },
      resultNum: 0
    }
  },
  created() {
    this.isSearch = JSON.parse(localStorage.getItem('isSearch'))
    const name = localStorage.getItem('name')
    this.filters.name = name
    console.log(this.isSearch, 56)
    const {fetchBrandList} = this
    fetchBrandList()
    this.$refs.productList.handleSearch(name)
  },
  mounted() {
    const name = localStorage.getItem('name')
    this.$refs.productList.handleSearch(name)
  },

  methods:{// 获取品牌列表
    async fetchBrandList(){
      const {currentBrandIndex} = this
      const res = await getBrandList()
      const {code, data} = res
      if(code === 0){
        const allOption = {name: 'All', id: ''}
        this.brandList = [allOption, ...data]
        console.log(this.brandList, 76)
        if(this.brandList.length > 0){
          const {id, name} = this.brandList[currentBrandIndex]
          this.currentBrandName = name
          this.currentBrandId = id
        }
      }
    },
    //切换品牌
    handleCommand({id, name}){
      this.currentBrandName = name
      this.currentBrandId = id
      console.log(this.currentBrandId, 89)
      this.$refs.productList.handleIsInputSearchValue()
    },
    handleInput(name){
      localStorage.setItem('name', name)
      this.$refs.productList.handleSearch(name)
    },
    updateResultNum(num){
      console.log(103, num)
      this.resultNum = num
    },
    async updateBrandIndex(index) {
      this.currentBrandIndex = index
      await this.fetchBrandList()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.custom-dropdown-menu {
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::v-deep .el-dropdown-menu__item{
    font-weight: 400;
    font-size: 16px;
    color: #151515;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    margin-bottom: 24px;
  }
}
.accessories-container {
  margin-top: 80px;
  .search{
    &-container{
      padding: 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #DADADA;
      ::v-deep .el-input{
        width: 900px;
        height: 60px;
        background: #F5F5F5;
        border-radius: 133px 133px 133px 133px;
        .el-input__inner{
          width: 100%;
          height: 100%;
          background: #F5F5F5;
          border-radius: 133px 133px 133px 133px;
          padding-left: 60px;
          font-size: 20px;

        }

      }
      ::v-deep .el-input__prefix{
        left: 30px;
      }
      ::v-deep .el-input__icon{
        font-size: 20px;
        margin-top: 3px;
      }
    }
    &-result{
      padding: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left{
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 19px;
        text-align: left;
        font-style: normal;
      }
      &-right{
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        display: flex;
        align-items: center;
        .title {
          margin-right: 8px;
          color: #888888;
        }
        .el-dropdown-link{
          font-weight: 400;
          font-size: 16px;
          color: #151515;
        }

      }
    }
  }

}
</style>
