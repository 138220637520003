var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "about-container" },
    [_vm._m(0), _c("Contact")],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "title" }, [_vm._v("About Us")]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "content-left" }, [
          _c("div", { staticClass: "content-inner" }, [
            _c("div", { staticClass: "inner-title" }, [
              _c("div", { staticClass: "icon" }),
              _c("span", [_vm._v("Company Overview")]),
            ]),
            _c("div", { staticClass: "inner-content" }, [
              _vm._v(
                " ZXL is a renowned international electronics accessories retail company with a history spanning over 3 years. During this time, we have established a strong presence in the global market and have become a trusted name among consumers. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "content-inner" }, [
            _c("div", { staticClass: "inner-title" }, [
              _c("div", { staticClass: "icon" }),
              _c("span", [_vm._v("Product Range")]),
            ]),
            _c("div", { staticClass: "inner-content" }, [
              _vm._v(
                " Our extensive product catalog is one of our key strengths. It includes a diverse selection of smartphone accessories. For instance, we offer a wide variety of smartphone cases crafted from different materials like hard plastic, silicone, and leather, available in an array of colors and designs to suit every taste and provide both protection and style. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "content-inner" }, [
            _c("div", { staticClass: "inner-title" }, [
              _c("div", { staticClass: "icon" }),
              _c("span", [_vm._v("Quality Assurance")]),
            ]),
            _c("div", { staticClass: "inner-content" }, [
              _vm._v(
                " Quality is the cornerstone of our business. We have forged partnerships with reliable manufacturers and suppliers who follow strict quality control procedures. Our in-house team of experts conducts rigorous inspections and testing on each product. They assess factors such as build quality, durability, and functionality to ensure that only products that meet our high standards reach our customers. "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content-right" }, [
          _c("img", {
            attrs: { src: require("@/assets/aboutUs/aboutUs.png"), alt: "" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }