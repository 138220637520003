var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "verify-container" }, [
    _c("div", { staticClass: "content" }, [
      _vm.resCode !== null
        ? _c("div", { staticClass: "icon" }, [
            _vm.resCode === 0
              ? _c("i", { staticClass: "el-icon-success" })
              : _c("i", {
                  staticClass: "el-icon-warning",
                  staticStyle: { color: "#f76260" },
                }),
          ])
        : _vm._e(),
      _c("span", { style: { color: _vm.isActivating ? "#5ea53b" : "#000" } }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
      _vm.isActivating !== false && (_vm.resCode === 0 || _vm.resCode === null)
        ? _c("span", [_vm._v(_vm._s(_vm.countdown) + " s")])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _vm.resCode === 6011
          ? _c(
              "el-button",
              {
                attrs: { type: "success", round: "" },
                on: { click: _vm.redirectToLogin },
              },
              [_vm._v("Login Now")]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _vm.resCode === 2009
          ? _c(
              "el-button",
              {
                attrs: { type: "success", round: "" },
                on: { click: _vm.redirectToRegister },
              },
              [_vm._v("Register Now")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }