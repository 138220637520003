<template>
  <div class="details-container">
    <div class="header">
      <div class="left">
        <div class="logo" @click="toHome" style="cursor: pointer">
          <img src="@/assets/index/logo.png" alt="">
        </div>
        <div class="line"></div>
        <div class="text">Order settlement</div>
      </div>
      <div class="right">
        <span>Contact Number</span>
        <span class="phone">+6288123456789</span>
      </div>
    </div>
    <div class="container">
      <div class="address-container">
        <div class="address-title">Receiving address</div>
        <div class="address-card-contain">
          <div class="address-card" :class="{active: currentIndex === index}" v-for="(item, index) in addressList" :key="item.id" @click="handleClickAddress(item, index)">
            <div class="card-title">{{item.firstName}} {{item.lastName}}</div>
            <div class="number">{{item.phone}}</div>
            <div class="address">{{item.city}},{{item.state}},{{item.country}},{{item.addressLine1}}</div>
            <div class="active-text" v-if="currentIndex === index"> <i class="el-icon-check"></i> </div>
            <div class="edit-text" @click.stop="toEditAddress(item)">Edit</div>
          </div>
          <div class="address-card add" @click="toAddAddress">
            <div class="add-img">
              <img src="@/assets/address/add-address.png" alt="">
            </div>
            <div class="add-text">Add address</div>
          </div>
        </div>
      </div>
      <div class="container-head">Products list</div>
      <div class="detail" v-if="orderVoLists.length > 0">
        <div class="detail-container" v-for="(item, index) in orderVoLists" :key="index">
          <div class="detail-left">
            <el-image
                style="width: 100px; height: 100px;margin-right: 40px"
                :src="item.thumbnail || item.cropThumbnail"
                fit="cover"
            >
            </el-image>
            <div class="right">
              <div class="name">{{item.productName}}</div>
              <div class="sku">{{item.productStockSkuValue}}</div>
            </div>
          </div>
          <div class="detail-right">
            <div class="price" v-if="item.productStockDiscountPrice">{{ currency(item.productStockDiscountPrice) }}</div>
            <div class="price" v-else>{{ currency(item.productDiscountPrice) }}</div>
            <div class="quantity">x{{item.quantity}}</div>
            <div class="amount">{{ currency(itemTotalPrices[index]) }}</div>
          </div>
        </div>

      </div>
      <div v-else class="empty">
        <el-empty description="No product information available at the moment"></el-empty>
      </div>
      <div class="subtotal">
        <div class="sub-title">Subtotal</div>
        <div class="price">{{currency(subTotalPrice)}}</div>
      </div>
      <div class="order-btn" @click="handleAddToCart">
        <el-button class="order">
          <span>Order Now</span>
          <span class="tip"> (Offline transfer)</span>
        </el-button>
      </div>
    </div>
    <el-dialog
        width="820px"
        :title="isEdit ? 'Edit address' : 'Add address'"
        :close-on-click-modal="false"
        :visible.sync="visible"
        @close="handleClose"
    >
      <div class="form-container">
        <el-form :inline="true" class="demo-form-inline" ref="ruleForm" :rules="rules" :model="addressForm">
          <el-row :gutter="24" class="contact-form-row">
            <el-col :span="12">
              <div class="input-title"><span style="color: #ff542f">*</span>First name</div>
              <el-form-item prop="firstName">
                <el-input placeholder="First name" type="text" v-model="addressForm.firstName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div class="input-title"><span style="color: #ff542f">*</span>Last name</div>
              <el-form-item prop="lastName">
                <el-input placeholder="Last name" type="text" v-model="addressForm.lastName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="contact-form-row">
            <el-col :span="12">
              <div class="input-title"><span style="color: #ff542f">*</span>Address</div>
              <el-form-item prop="addressLine1">
                <el-input placeholder="Address" type="text" v-model="addressForm.addressLine1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div class="input-title"><span style="color: #ff542f">*</span>City</div>
              <el-form-item prop="city">
                <el-input placeholder="City" type="text" v-model="addressForm.city"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="contact-form-row">
            <el-col :span="12">
              <div class="input-title"><span style="color: #ff542f">*</span>State/Province</div>
              <el-form-item prop="state">
                <el-input placeholder="State/Province" type="text" v-model="addressForm.state"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div class="input-title">Country/Region</div>
              <el-form-item>
                <el-input placeholder="Country/Region" type="text" v-model="addressForm.country"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="contact-form-row">
            <el-col :span="12">
              <div class="input-title"><span style="color: #ff542f">*</span>Email address</div>
              <el-form-item prop="email">
                <el-input placeholder="Email address" type="text" v-model="addressForm.email"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div class="input-title"><span style="color: #ff542f">*</span>Phone number</div>
              <el-form-item prop="phone">
                <el-input placeholder="Phone number" type="text" v-model="addressForm.phone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="button-container">
        <el-button @click="handleClose" class="cancel">cancel</el-button>
        <el-button class="confirm" @click="submit">confirm</el-button>
      </div>
    </el-dialog>
    <el-dialog
        width="820px"
        title="Order Reminder"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        @close="handleReminderClose"
        class="reminder"
    >
      <div class="tips">
        <img src="@/assets/order/tip-1.png" alt="">
        <span class="tips-text">Please transfer funds to the following account using offline methods,
          and provide your order number for our backend verification.
          We will confirm within one day.
          Thank you for your cooperation.</span>
      </div>
      <div class="form">
        <div class="form-title">Receiving account</div>
        <div class="form-content">
          <div class="form-content-inner">
            <div class="content-container">
              <span class="inner-title">Account Name:</span>
              <span class="inner-text"> Antony Edwords</span>
            </div>
            <div class="content-container">
              <span class="inner-title">Card number:</span>
              <span class="inner-text"> 4539 6876 5432 1098</span>
              <el-button class="copy-btn" v-clipboard:copy="'4539 6876 5432 1098'" v-clipboard:success="handleCopy">Copy</el-button>
            </div>
          </div>
          <div class="form-content-inner">
            <div class="content-container"><span class="inner-title">bank:</span>
              <span class="inner-text"> DBS Debit Card</span></div>
            <div class="content-container"><span class="inner-title">swift code:</span>
              <span class="inner-text"> W1695242884</span></div>
          </div>
          <div class="form-content-inner">
            <div class="content-container">
              <span class="inner-title">Order Number:</span>
              <span class="inner-text"> {{orderNo}}</span>
              <el-button class="copy-btn" v-clipboard:copy="orderNo" v-clipboard:success="handleCopy">Copy</el-button>
            </div>

          </div>

        </div>
      </div>
      <div slot="footer" class="button-container">
        <el-button class="confirm" @click="handleReminderSubmit">Get it</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {cartList} from "@/api/cart";
import {ORDER_SOURCE} from "@/tool/enum";
import {getAddressList, upsertAddress} from "@/api/address";
import {currency} from "../../../utils/util";
import {order} from "@/api/order";
const defaultCurrentForm = ()=>({
  source: '',
  userAddressId: '',
  quantity: '',
  totalAmount: '',
  products: [],
})
const defalutAddressForm = ()=>({
  "id": null,
  firstName: "",    //名
  lastName: "",     //姓
  country: "",    //国家
  addressLine1: "",    //地址1
  addressLine2: "",
  city: "",              //城市
  state: "",             //地区
  email: "",        //邮箱
  phone: "",       //电话
  isDefault: 0
})
export default {
  name: "confirmOrder",
  data() {
    return {
      detailData: [],
      orderVoLists: [],
      currentForm: defaultCurrentForm(),
      addressForm: defalutAddressForm(),
      subTotalPrice: 0,
      ORDER_SOURCE,
      loading: false,
      visible: false,
      dialogVisible: false,
      addressList: [],
      currentIndex: '',
      rules: {
        firstName: [{ required: true, trigger: 'blur', message: 'First name is required' }],
        lastName: [{ required: true, trigger: 'blur', message: 'Last name is required' }],
        addressLine1: [{ required: true, trigger: 'blur', message: 'Address is required' }],
        city: [{ required: true, trigger: 'blur', message: 'City is required' }],
        state: [{ required: true, trigger: 'blur', message: 'State is required' }],
        email: [
          { required: true, trigger: 'blur', message: 'Email is required' },
          {type:'email', trigger: 'blur', message: 'Please enter a valid email address' },
        ],
        phone: [{ required: true, trigger: 'blur', message: 'Phone Number is required' }],
      },
      isEdit: false,
      timer: null,
      orderList: [],
      orderNo: '',
      isThrottled: false,
      isSaving: false
    }
  },
  async mounted() {
    const source = localStorage.getItem('source');
    console.log(source, 100)
    this.currentForm.source = source
    if(Number(source) === ORDER_SOURCE.Detail){
      this.orderVoLists = JSON.parse(localStorage.getItem('confirmOrderProductDetail'))
      console.log(this.orderVoLists, 81);
    }else if(Number(source) === ORDER_SOURCE.Cart){
      await this.fetchCartList()
      console.log(this.orderVoLists, 82)
    }
    this.calculateSubTotal()
    await this.fetchAddressList()
  },
  computed: {
    // 计算单个商品价格
    itemTotalPrices() {
      return this.orderVoLists.map(item => {
        const price = item.productStockDiscountPrice ? item.productStockDiscountPrice : item.productDiscountPrice
        return item.quantity * price
      })
    }
  },
  methods:{
    currency,
    // 获取购物车列表
    async fetchCartList() {
      const res = await cartList()
      const {code, data} = res
      if (code === 0) {
        this.orderVoLists = data.map(item => {
          return {
            ...item,
            productStockSkuValue:item.productStockSkuValue && JSON.parse(item.productStockSkuValue).join(' / ')
          }
        })
        console.log(this.orderVoLists, 'cartList-----')
      }
    },
    // 获取地址列表
    async fetchAddressList(){
      const res = await getAddressList()
      const {code, data} = res
      if(code === 0) {
        this.addressList = data
      }
    },
    // 产品总价
    calculateSubTotal() {
      this.subTotalPrice = this.orderVoLists.reduce((total, item) => {
        const price = item.productStockDiscountPrice ? item.productStockDiscountPrice : item.productDiscountPrice
        return total + (item.quantity * price)
      }, 0)
      this.currentForm.totalAmount = this.subTotalPrice.toFixed(2)
    },
  //   添加地址
    toAddAddress(){
      this.visible = true
      this.isEdit = false
      this.addressForm = defalutAddressForm()
    },
    handleClickAddress(item, index){
      const {id} = item
      this.currentForm.userAddressId = id
      console.log('编辑')
      this.currentIndex = index

    },
    toEditAddress(item){
      this.visible = true
      this.isEdit = true
      this.addressForm = {...item}
    },
    handleClose(){
      this.visible = false
    },
  //   提交表单
    submit(){
      this.$refs.ruleForm.validate(async valid => {
        console.log(this.addressForm, 251)
        if (valid) {
          const res = await upsertAddress({...this.addressForm})
          const {code} = res
          if(code === 0){
            this.$message.success('Added successfully!')
            this.visible = false
            await this.fetchAddressList()
          }
        }
      })
    },
    handleAddToCart() {
      // 节流
      if (this.isThrottled) return;
      this.isThrottled = true;
      this.save();
      setTimeout(() => {
        this.isThrottled = false;
      }, 500);
    },
  //   下单
    async save(){
      console.log(this.isSaving, 379)
      if(this.currentForm.userAddressId === ''){
        this.$message.warning('Please select a shipping address!')
        return
      }
      if(this.isSaving){
        this.dialogVisible = true
        return
      }
      this.isSaving = true
        console.log(this.orderVoLists, 291)
        this.currentForm.products = this.orderVoLists.map(item => {
          return {
            quantity: item.quantity,
            productId: item.productId,
            productStockId: item.productStockId
          }
        })
        this.currentForm.quantity = this.orderVoLists.reduce((total, item) => {
          return total + item.quantity;
        }, 0)
        console.log(this.currentForm, 280)
        const res = await order({...this.currentForm})
        const {code, data} = res
        if (code === 0) {
          this.$message.success('Order placed successfully!')
          this.orderNo = data.orderNo
          setTimeout(async () => {
            await this.$store.dispatch('cart/fetch')
            localStorage.removeItem('source')
            this.dialogVisible = true
          }, 300)
        }

    },
    handleReminderClose(){
      this.dialogVisible = false
      this.$router.push({path: 'orderHistory'})
    },
    handleReminderSubmit() {
      this.$router.push({path: 'orderHistory'})
    },
    //   复制
    handleCopy(){
      this.$message.success('Replicating Success!')
    },
    toHome(){
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.details-container {
  .header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
    .left{
      display: flex;
      align-items: center;

      .line{
        height: 36px;
        width: 0px;
        border: 1px solid #C0C0C0;
        margin: 0 30px;
      }
      .text{
        font-weight: 500;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
      }
    }

    .right{
      font-weight: 400;
      font-size: 14px;
      color: #888888;
      line-height: 16px;
      .phone{
        font-weight: 500;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        margin-left: 10px;
      }
    }

  }

  .container {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    .address-container{
      margin: 43px 0 54px;
      .address-title{
        font-weight: 600;
        font-size: 20px;
        color: #151515;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        margin-bottom: 17px;
      }
      .address-card-contain{
        display: flex;
        flex-wrap: wrap;
        .address-card{
          width: 340px;
          height: 200px;
          background: #FFFFFF;
          border-radius: 5px 5px 5px 5px;
          border: 1px solid #E3E3E3;
          padding: 28px 0 0 33px;
          box-sizing: border-box;
          text-align: left;
          cursor: pointer;
          position: relative;
          margin-right: 30px;
          margin-bottom: 30px;
          .card-title{
            font-weight: 400;
            font-size: 18px;
            color: #151515;
            line-height: 21px;
            text-align: left;
            font-style: normal;
            margin-bottom: 25px;
          }
          .number{
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            margin-bottom: 14px;
          }
          .address{
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 16px;
            text-align: left;
            font-style: normal;
          }
          .edit-text{
            font-weight: 400;
            font-size: 14px;
            color: #1C7FF1;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            position: absolute;
            right: 19px;
            bottom: 17px;
            display: none;
          }

        }
        .address-card:hover .edit-text{
          display: block;
        }
        .active{
          border: 1px solid #151515;
          position: relative;
          .active-text{
            position: absolute;
            right: 0;
            top: 0;
            width: 24px;
            height: 24px;
            text-align: center;
            background: #151515;
            border-bottom-left-radius: 5px;
            .el-icon-check{
              font-size: 12px;
              color: #fff;
            }
          }
        }
        .add{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0;
          cursor: pointer;
          .add-img{
            width: 40px;
            height: 40px;
            margin-bottom: 12px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .add-text{
            font-weight: 400;
            font-size: 14px;
            color: #151515;
            line-height: 16px;
            text-align: center;
            font-style: normal;
          }
        }
      }
      .address-card-contain:nth-child(3n){
        margin-right: 0;
      }
      .address-card-contain:last-child{
        margin-right: 0;
      }
    }
    .container-head{
      font-weight: 600;
      font-size: 20px;
      color: #151515;
      line-height: 23px;
      text-align: left;
      font-style: normal;
      padding-bottom: 16px;
      border-bottom: 1px solid #DADADA;
    }

    .detail::-webkit-scrollbar {
      display: none;
    }
    .detail{
      padding: 14px 0;
      max-height: 500px;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 116px;
        .price{
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          line-height: 19px;
          text-align: left;
        }
        .detail-left{
          display: flex;
          align-items: center;
          img{
            width: 80px;
            height: 80px;
            margin-right: 30px;
            border-radius: 5px;
          }
          .right{
            text-align: left;
            .name{
              font-weight: 500;
              font-size: 16px;
              color: #151515;
              line-height: 19px;
              text-align: left;
              font-style: normal;
              margin-bottom: 12px;
            }
            .sku{
              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 14px;
              color: #888888;
              line-height: 16px;
              text-align: left;
              font-style: normal;
            }
          }
        }
        .detail-right{
          min-width: 400px;
          display: flex;
          align-items: center;
          .price {
            text-align: left;
            width: 170px;
          }
          .quantity {
            color: #666666;
            width: 150px;
            text-align: left;
          }
        }
      }
    }
    .subtotal{
      display: flex;
      justify-content: right;
      align-items: center;
      padding-top: 37px;
      border-top: 1px solid #DADADA;
      .sub-title{
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        margin-right: 250px;
      }
      .price{
        font-weight: 500;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
      }
    }
    .order-btn{
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 52px;
      margin-bottom: 80px;
      .order{
        width: 244px;
        height: 47px;
        font-family: MiSans, MiSans;
        background: #333333;
        border-radius: 8px 8px 8px 8px;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        font-style: normal;
        .tip{
          font-size: 12px;
        }
      }
      .disabled{
        opacity: 0.4;
      }
    }
  }
  ::v-deep .el-dialog{
    border-radius: 18px;
    padding: 22px 25px 28px 30px;
  }
  ::v-deep .el-dialog__header{
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    color: #151515;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    padding: 0;
  }
  ::v-deep .el-dialog__body{
    padding: 34px 38px 86px 45px;
  }
  .form-container{
    box-sizing: border-box;
    text-align: left;
    .input-title{
      font-weight: 400;
      font-size: 16px;
      color: #151515;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      margin-bottom: 12px;
    }
    ::v-deep .el-input__inner{
      width: 312px;
      height: 40px;
      border-radius: 3px 3px 3px 3px;
      //margin-bottom: 24px;
    }
    ::v-deep .el-textarea__inner{
      width: 698px;
    }
  }
  .cancel{
    width: 144px;
    height: 51px;
    background: #F3F3F3;
    border-radius: 6px 6px 6px 6px;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    font-style: normal;
    text-align: center;
    margin-right: 20px;
  }
  .confirm{
    width: 144px;
    height: 51px;
    border-radius: 6px 6px 6px 6px;
    background: #151515;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    font-style: normal;
    text-align: center;

  }
  .reminder{
    ::v-deep .el-dialog__body{
      padding: 28px 12px;
    }
  }
  .tips{
    width: 100%;
    height: 56px;
    background: #FFF6E5;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img{
      width: 18px;
      height: 18px;
      margin-left: 18px;
      margin-right: 16px;
    }
    .tips-text{
      font-family: Roboto, Roboto;
      font-weight: 400;
      font-size: 14px;
      color: #FF6912;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      word-break: keep-all;
    }

  }
  .form{
    //width: 100%;
    height: 200px;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #DCDCDC;
    padding: 33px 0 0 36px;
    .form-title{
      font-family: Roboto, Roboto;
      font-weight: 600;
      font-size: 20px;
      color: #151515;
      line-height: 23px;
      text-align: left;
      font-style: normal;
      margin-bottom: 30px;
    }
    .form-content{
      display: flex;
      flex-wrap: wrap;
      .form-content-inner{
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .content-container{
          flex: 1;
          text-align: left;
        }
        .inner-title{
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 14px;
          color: #888888;
          line-height: 16px;
          text-align: left;
          font-style: normal;
        }
        .inner-text{
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }
        ::v-deep .el-button{
          padding: 0;
        }
        .copy-btn{
          width: 48px;
          height: 22px;
          background: #FFFFFF;
          border-radius: 100px 100px 100px 100px;
          border: 1px solid #DCDCDC;
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          font-style: normal;
          margin-left: 8px;

        }
      }
    }
  }
}
</style>
