<template>
<div class="verify-container">
  <div class="content">
    <div class="icon" v-if="resCode !== null">
      <i class="el-icon-success" v-if="resCode === 0"></i>
      <i class="el-icon-warning" v-else style="color: #f76260"></i>
    </div>
    <span :style="{color: isActivating ? '#5ea53b' : '#000'}">{{text}}</span>
    <span v-if="isActivating !== false && (resCode === 0 || resCode === null) ">{{ countdown }} s</span>
  </div>
  <div class="btn"><el-button type="success" round v-if="resCode === 6011" @click="redirectToLogin">Login Now</el-button></div>
  <div class="btn"><el-button type="success" round v-if="resCode === 2009" @click="redirectToRegister">Register Now</el-button></div>
</div>
</template>

<script>
import { verify } from "@/api/login";

export default {
  name: "verifyPage",
  data() {
    return {
      text: 'Activating in progress...',
      countdown: 3,
      timer: null,
      isActivating: null,
      resCode: null
    };
  },
  mounted() {
    this.startInitialCountdown();
  },
  methods: {
    // 初始倒计时
    startInitialCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 1) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.handleActivation();
        }
      }, 1000);
    },
    // 激活邮箱
    async handleActivation() {
      const code = this.$route.query.code;
      const res = await verify(code);
      const { code:responseCode  } = res;
      this.resCode = responseCode
      if (responseCode === 0) {
        this.isActivating = true
        this.text = 'Activation successful! will soon return to the login page...';
        this.countdown = 3
        this.startSuccessCountdown();
      }else if(responseCode === 2009){
        this.text = "The activation link has failed, please register again";
      }else if(responseCode === 6011){
        this.text = "This link has already been activated";
      }else{
        this.isActivating = false
        this.redirectToLogin();
      }
    },
    startSuccessCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 1) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.redirectToLogin();
        }
      }, 1000);
    },
    // 跳转到登录页面
    redirectToLogin() {
      this.$router.push({ path: '/login' });
    },
    redirectToRegister(){
      this.$router.push({
        path: '/register'
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },

};
</script>


<style scoped lang="scss">
.verify-container{
  .content{
    margin: 100px auto 50px;
    text-align: center;
    font-size: 30px;
    .icon{
      font-size: 60px;
      color:#5ea53b;
      margin-bottom: 20px;
    }
  }
  .active{
    color: #5ea53b;
  }
  .btn{

  }
}
</style>
