import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}
export function logout(data) {
    return request({
        url: '/logout',
        method: 'post',
        data
    })
}
export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        data
    })
}

// 激活邮箱
export function verify(verifyCode) {
    return request({
        url: `/register/activation?verifyCode=${verifyCode}`,
        method: 'post'
    })
}


