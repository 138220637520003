var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-container" },
    [
      _c(
        "div",
        { staticClass: "device-left", class: { sticky: _vm.isSticky } },
        [
          _c(
            "el-collapse",
            {
              on: { change: _vm.handleChange },
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "Product", name: "1" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading.loadingCategory,
                          expression: "loading.loadingCategory",
                        },
                      ],
                    },
                    [
                      _vm.categoryList.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.categoryList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "model-name",
                                  class: {
                                    active: _vm.currentCategoryIndex === index,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckCategory(
                                        item,
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.currentCategoryIndex === index
                                    ? _c("div", { staticClass: "icon" })
                                    : _c("div", {
                                        staticStyle: {
                                          width: "6px",
                                          height: "6px",
                                        },
                                      }),
                                  _vm._v(" " + _vm._s(item.name) + " "),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "text-align": "center",
                              },
                            },
                            [_vm._v("No products available at the moment")]
                          ),
                    ]
                  ),
                ]
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "Models", name: "2" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading.loadingModels,
                          expression: "loading.loadingModels",
                        },
                      ],
                    },
                    [
                      _vm.modelsList.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.modelsList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "model-name",
                                  class: {
                                    active: _vm.currentModelIndex === index,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckModel(item, index)
                                    },
                                  },
                                },
                                [
                                  _vm.currentModelIndex === index
                                    ? _c("div", { staticClass: "icon" })
                                    : _c("div", {
                                        staticStyle: {
                                          width: "6px",
                                          height: "6px",
                                        },
                                      }),
                                  _vm._v(" " + _vm._s(item.name) + " "),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "text-align": "center",
                              },
                            },
                            [_vm._v("There are currently no models available")]
                          ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.loadingProduct,
              expression: "loading.loadingProduct",
            },
          ],
          staticClass: "device-right",
        },
        [
          _vm.productList.length > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "device-right-container" },
                  _vm._l(_vm.productList, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "device-info" },
                      [
                        item.cropThumbnail || item.thumbnail
                          ? _c("div", { staticClass: "image-container" }, [
                              _c("img", {
                                attrs: {
                                  src: item.cropThumbnail || item.thumbnail,
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toProductDetail(
                                      item.id,
                                      "toOrder"
                                    )
                                  },
                                },
                              }),
                            ])
                          : _c("div", { staticClass: "image-container" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/product/product.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toProductDetail(
                                      item.id,
                                      "toOrder"
                                    )
                                  },
                                },
                              }),
                            ]),
                        _c("div", { staticClass: "info" }, [
                          _c(
                            "div",
                            { staticClass: "info-left" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: item.name,
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "price" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "16px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.currency(item.discountPrice))
                                    ),
                                  ]
                                ),
                                item.discountPrice !== item.price
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#BABABA",
                                          "text-decoration": "line-through",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.currency(item.price)))]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "info-right",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.toProductDetail(item.id, "toCart")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/index/gowuche.png"),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm.showLoadMore
                  ? _c(
                      "div",
                      {
                        staticClass: "device-footer",
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.handleLoadMore },
                      },
                      [_vm._v("Load more")]
                    )
                  : _vm._e(),
              ])
            : _c(
                "div",
                [_c("el-empty", { attrs: { description: "No Product" } })],
                1
              ),
        ]
      ),
      _c("ProductDetail", { ref: "productDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }