<template>
<div class="contact">
  <div class="contact-container">
    <div class="contact-inner-container">
      <div class="title">About Us</div>
      <div class="center" @click="toAboutUs" style="cursor: pointer">About Us</div>
      <div class="foot">Privacy Policy</div>
    </div>
    <div class="contact-inner-container">
      <div class="title">My Account</div>
      <div class="center">My Account</div>
      <div class="foot"  @click="toOrderHistory" style="cursor: pointer">Order History</div>
    </div>
    <div class="contact-inner-container">
      <div class="title">Contact Us</div>
      <div class="pic-container">
        <img src="@/assets/index/f-word.png" alt="">
        <img src="@/assets/index/camera.png" alt="">
        <img src="@/assets/index/bird.png" alt="">
        <img src="@/assets/index/wechat.png" alt="">
        <img src="@/assets/index/phone.png" alt="">
      </div>
    </div>
<!--    <div class="contact-inner-container">-->
<!--      <div class="title">Newsletter</div>-->
<!--      <div class="input-container">-->
<!--        <el-input placeholder="Email address">-->
<!--          <template slot="append">Send</template>-->
<!--        </el-input>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="footer-container">
    Copyright © 2024 Shadowsocks.com. All Rights Reserved.
  </div>
</div>
</template>

<script>
export default {
  name: 'contactIndex',
  computed: {
    hasToken() {
      return !!this.$store.getters.token
    }
  },
  methods:{
    toAboutUs(){
      if (this.$route.path !== '/aboutUs') {
        this.$router.push('/aboutUs');
      }
    },
    toOrderHistory(){
      if(!this.hasToken){
        this.$router.push({ path: '/login' })
        return
      }
      if (this.$route.path !== '/orderHistory') {
        this.$router.push('/orderHistory');
      }
    }
  }
}
</script>


<style scoped lang="scss">
.contact{
  border-top: 1px solid #DADADA;
  .contact-container{
    width: 1080px;
    margin: 50px auto;
    display: flex;
    justify-content: start;
    .contact-inner-container {
      text-align: left;
      .title{
        width: 200px;
        font-weight: 600;
        font-size: 16px;
        color: #151515;
        margin-bottom: 23px;
      }
      .center{
        width: 200px;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-bottom: 14px;
      }
      .pic-container{
        width: 450px;
        img{
          width: 36px;
          height: 36px;
          margin-right: 16px;
        }
      }
      .foot{
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
      ::v-deep .el-input-group__append{
        background: #333333;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
      }
    }
  }
  .footer-container{
    width: 100%;
    height: 72px;
    line-height: 72px;
    background: #333333;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
  }
}
</style>
