<template>
  <div class="index-container">
    <div class="header" :class="{ white: isHeaderWhite }">
      <div class="header-container">
        <div class="logo">
          <img src="@/assets/index/logo.png" alt="">
        </div>
        <div class="navbar">
          <ul>
            <li><router-link  :to="{ path: '/'}">HOME</router-link></li>
            <li><router-link  :to="{ path: '/accessories'}">ACCESSORIES</router-link></li>
            <li><router-link  :to="{ path: '/aboutUs'}">ABOUT US</router-link></li>
            <li><router-link  :to="{ path: '/contactUs'}">CONTACT US</router-link></li>
          </ul>
        </div>
        <div class="right">
          <div class="cart" @click="toShopCard">
            <el-badge :value="cartItemCount" class="badge">
              <i class="el-icon-goods"></i>
            </el-badge>
          </div>
          <div>
            <el-popover
                placement="top-start"
                width="150"
                trigger="hover"
                :visible-arrow="false"
            >
              <div class="user-info" style="padding: 0 20px">
                <div class="user-action" v-if="hasToken" @click="handleLogout">Logout</div>
                <div class="user-action" v-else @click="toLogin">Login/Register</div>
                <div class="user-action" @click="toOrderHistory">Order History</div>
              </div>
              <i class="el-icon-user" slot="reference" style="cursor: pointer"></i>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-container">
      <div class="banner">
        <img src="@/assets/index/banner.png" alt="">
      </div>
      <div class="search-container">
        <el-input
            placeholder="Search for accessories"
            prefix-icon="el-icon-search"
            v-model="filters.name"
            @keyup.enter.native="toSearch"
        >
        </el-input>
      </div>
    </div>
    <div class="logo-container sticky" ref="logoContainer">
      <div class="device-logo" :class="{active: currentBrandIndex === index}" v-for="(item, index) in brandList" :key="item.id" @click="checkBrand(item, index)">
        <img v-if="item.image" :src="item.image" :class="{ transparent: currentBrandIndex !== index }">
      </div>
    </div>
    <ProductIndex :currentBrandId="currentBrandId" :isSticky="isSticky"></ProductIndex>
    <div class="FAQs-container">
      <div class="FAQ-title">FAQs</div>
      <div class="FAQ-content">
        <div class="content-left">How to proceed with logistics transportation after placing an order?</div>
        <div class="content-right">+</div>
      </div>
      <div class="FAQ-content">
        <div class="content-left">What if l can't find the accessories i'm looking for?</div>
        <div class="content-right">+</div>
      </div>
      <div class="FAQ-content">
        <div class="content-left">What is the return policy?</div>
        <div class="content-right">+</div>
      </div>
      <div class="FAQ-content">
        <div class="content-left">What are the customer service hours?</div>
        <div class="content-right">+</div>
      </div>
      <div class="FAQ-content">
        <div class="content-left">Are all accessories guaranteed to be genuine?</div>
        <div class="content-right">+</div>
      </div>
    </div>
    <Contact></Contact>
    <ShopCard ref="shopCard"></ShopCard>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
import ProductIndex from "@/components/product/product.vue";
import {getBrandList, getCategoryList} from '@/api/device'
export default {
  name: 'HomeIndex',
  components: { ProductIndex, Contact},
  data () {
    return {
      brandList: [],
      currentBrandIndex: 0,
      categoryList: [],
      loadingCategory: false,
      isHeaderWhite: false,
      isSticky: false,
      filters: {
        name: ''
      },
      currentBrandId: ''
    }
  },
  computed: {
    hasToken() {
      return !!this.$store.getters.token
    },
    cartItemCount() {
      return this.hasToken ? this.$store.getters.cart.length : 0
    }
  },
  async mounted() {
    const {fetchBrandList, currentBrandIndex} = this
    await fetchBrandList()
    if(this.brandList.length > 0){
      const {id} = this.brandList[currentBrandIndex]
      this.currentBrandId = id
    }
    window.addEventListener('scroll', this.handleScroll);

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{// 获取品牌列表
    async fetchBrandList(){
      const res = await getBrandList()
      const {code, data} = res
      if(code === 0){
        this.brandList = data
      }
    },
    // 点击品牌
    checkBrand(item,index){
      this.currentBrandIndex = index
      const {id} = item
      this.currentBrandId = id
    },
    // 搜索
    toSearch(){
      const { name } = this.filters
      localStorage.setItem('isSearch', 'true')
      localStorage.setItem('name', name)
      this.$router.push({
        path: 'search'
      })
    },
    // 处理页面上滑
    handleScroll() {
      const logoContainer = this.$refs.logoContainer;
      const scrollTop = window.scrollY;
      this.isHeaderWhite = scrollTop > 50;
      this.isSticky = logoContainer.getBoundingClientRect().top <= 80;
    },
    toLogin () {
      this.$router.push({
        path: '/login'
      })
    },
    async handleLogout() {
      await this.$store.dispatch('user/logout')
      await this.$router.push({ path: '/login' })
    },
    toOrderHistory(){
      if(!this.hasToken){
        this.$router.push({ path: '/login' })
        return
      }
      this.$router.push({
        path: '/orderHistory'
      })
    },
    toShopCard(){
      if(!this.hasToken){
        this.$router.push({
          path: '/login'
        })
      }else{
        console.log('shopCard-----------', this.$refs.shopCard)
        this.$refs.shopCard.handleOpen()
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.index-container{
  .header {
    transition: background-color 0.3s;
  }
  .header.white {
    background-color: white;
  }
  .logo-container.sticky {
    position: sticky;
    top: 80px;
    z-index: 10;
    background: #fff;
  }
  .header{
    position: fixed;
    width: calc(100vw - 17px);
    min-width: 1440px;
    top: 0;
    left: 0;
    height: 80px;
    z-index: 10;
    padding: 30px 24px;
    box-sizing: border-box;
    .header-container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo{
        width: 79px;
        height: 36px;
        & > img {
          width: 79px;
          height: 36px;
        }
      }
      .navbar{
        ul{
          display: flex;
          justify-content: space-between;
          list-style: none;
          li{
            margin-right: 64px;
          }
          a{
            text-decoration: none;
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            line-height: 25px;
            text-align: left;
            font-style: normal;
            text-transform: none
          }
        }
      }
      .right{
        display: flex;
        justify-content: space-between;
        width: 65px;
        padding: 0 6px;
        font-size: 18px;
        .cart{
          cursor: pointer;
          ::v-deep .el-badge__content {
            background-color: #FF6912;
          }
        }
      }
    }
  }
  .banner-container{
    width: 100%;
    height: 520px;
    position: relative;
    .banner{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      @media (min-width: 1440px) {
        img{
          position:absolute;
          top:0;
          left:50vw;
          margin-left: -960px;
          width: 1920px;
          height: 520px;
          border:none;
        }
      }
      @media (max-width: 1440px) {
        img{
          position:absolute;
          top:0;
          left: 720px;
          margin-left: -960px;
          width: 1920px;
          height: 520px;
          border:none;
        }
      }
    }
    .search-container {
      position: relative;
      top: 347px;
      text-align: center;
      ::v-deep .el-input{
        width: 400px;
        height: 60px;
        .el-input__inner{
          width: 100%;
          height: 100%;
          border: none;
          background: #FFFFFF;
          border-radius: 133px;
          padding-left: 60px;
          font-size: 16px;
        }
      }
      ::v-deep .el-input__prefix{
        left: 30px;
      }
      ::v-deep .el-input__icon{
        font-size: 20px;
        margin-top: 3px;
      }
    }
  }

  .logo-container{
    padding: 24px;
    text-align: left;
    .device-logo{
      display: inline-block;
      margin-right: 24px;
      width: 150px;
      height: 66px;
      box-sizing: border-box;
      padding: 11px 17px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #E5E5E5;
      cursor: pointer;
      color: #888888;
      img{
        width: 114px;
        height: 42px;
        box-sizing: border-box;
      }
      .transparent {
        opacity: 0.4;
      }
    }
    .active{
      border: 1px solid #333333;
      color: #151515;
    }
  }

  .FAQs-container{
    width: 960px;
    margin: 70px auto 90px;
    .FAQ-title{
      font-weight: 600;
      font-size: 28px;
      color: #151515;
      line-height: 33px;
      text-align: left;
      font-style: normal;
      margin-bottom: 17px;
    }
    .FAQ-content{
      height: 60px;
      text-align: left;
      line-height: 60px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #DADADA;
      font-weight: 400;
      font-size: 14px;
      color: #151515;
      font-style: normal;
      .content-right{
        font-size: 24px;
        margin-right: 14px;
      }
    }
  }
}
.el-popover, .el-popper {
  .user-info {
    .user-action {
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      font-family: Roboto, Roboto;
      font-style: normal;
    }
  }
}
</style>
