var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details-container" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "left" }, [
          _c(
            "div",
            {
              staticClass: "logo",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.toHome },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/index/logo.png"), alt: "" },
              }),
            ]
          ),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "text" }, [_vm._v("Order settlement")]),
        ]),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "address-container" }, [
          _c("div", { staticClass: "address-title" }, [
            _vm._v("Receiving address"),
          ]),
          _c(
            "div",
            { staticClass: "address-card-contain" },
            [
              _vm._l(_vm.addressList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "address-card",
                    class: { active: _vm.currentIndex === index },
                    on: {
                      click: function ($event) {
                        return _vm.handleClickAddress(item, index)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(item.firstName) + " " + _vm._s(item.lastName)
                      ),
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.phone)),
                    ]),
                    _c("div", { staticClass: "address" }, [
                      _vm._v(
                        _vm._s(item.city) +
                          "," +
                          _vm._s(item.state) +
                          "," +
                          _vm._s(item.country) +
                          "," +
                          _vm._s(item.addressLine1)
                      ),
                    ]),
                    _vm.currentIndex === index
                      ? _c("div", { staticClass: "active-text" }, [
                          _c("i", { staticClass: "el-icon-check" }),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "edit-text",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.toEditAddress(item)
                          },
                        },
                      },
                      [_vm._v("Edit")]
                    ),
                  ]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "address-card add",
                  on: { click: _vm.toAddAddress },
                },
                [
                  _vm._m(1),
                  _c("div", { staticClass: "add-text" }, [
                    _vm._v("Add address"),
                  ]),
                ]
              ),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "container-head" }, [_vm._v("Products list")]),
        _vm.orderVoLists.length > 0
          ? _c(
              "div",
              { staticClass: "detail" },
              _vm._l(_vm.orderVoLists, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "detail-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "detail-left" },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            "margin-right": "40px",
                          },
                          attrs: {
                            src: item.thumbnail || item.cropThumbnail,
                            fit: "cover",
                          },
                        }),
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.productName)),
                          ]),
                          _c("div", { staticClass: "sku" }, [
                            _vm._v(_vm._s(item.productStockSkuValue)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "detail-right" }, [
                      item.productStockDiscountPrice
                        ? _c("div", { staticClass: "price" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currency(item.productStockDiscountPrice)
                              )
                            ),
                          ])
                        : _c("div", { staticClass: "price" }, [
                            _vm._v(
                              _vm._s(_vm.currency(item.productDiscountPrice))
                            ),
                          ]),
                      _c("div", { staticClass: "quantity" }, [
                        _vm._v("x" + _vm._s(item.quantity)),
                      ]),
                      _c("div", { staticClass: "amount" }, [
                        _vm._v(
                          _vm._s(_vm.currency(_vm.itemTotalPrices[index]))
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "empty" },
              [
                _c("el-empty", {
                  attrs: {
                    description:
                      "No product information available at the moment",
                  },
                }),
              ],
              1
            ),
        _c("div", { staticClass: "subtotal" }, [
          _c("div", { staticClass: "sub-title" }, [_vm._v("Subtotal")]),
          _c("div", { staticClass: "price" }, [
            _vm._v(_vm._s(_vm.currency(_vm.subTotalPrice))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "order-btn", on: { click: _vm.handleAddToCart } },
          [
            _c("el-button", { staticClass: "order" }, [
              _c("span", [_vm._v("Order Now")]),
              _c("span", { staticClass: "tip" }, [
                _vm._v(" (Offline transfer)"),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "820px",
            title: _vm.isEdit ? "Edit address" : "Add address",
            "close-on-click-modal": false,
            visible: _vm.visible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-container" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    rules: _vm.rules,
                    model: _vm.addressForm,
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "contact-form-row", attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _c("span", { staticStyle: { color: "#ff542f" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("First name"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "firstName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "First name",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.addressForm.firstName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addressForm, "firstName", $$v)
                                  },
                                  expression: "addressForm.firstName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _c("span", { staticStyle: { color: "#ff542f" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("Last name"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "lastName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Last name",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.addressForm.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addressForm, "lastName", $$v)
                                  },
                                  expression: "addressForm.lastName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "contact-form-row", attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _c("span", { staticStyle: { color: "#ff542f" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("Address"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "addressLine1" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Address", type: "text" },
                                model: {
                                  value: _vm.addressForm.addressLine1,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addressForm,
                                      "addressLine1",
                                      $$v
                                    )
                                  },
                                  expression: "addressForm.addressLine1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _c("span", { staticStyle: { color: "#ff542f" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("City"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "city" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "City", type: "text" },
                                model: {
                                  value: _vm.addressForm.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addressForm, "city", $$v)
                                  },
                                  expression: "addressForm.city",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "contact-form-row", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _c("span", { staticStyle: { color: "#ff542f" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("State/Province"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "state" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "State/Province",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.addressForm.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addressForm, "state", $$v)
                                  },
                                  expression: "addressForm.state",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _vm._v("Country/Region"),
                          ]),
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Country/Region",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.addressForm.country,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addressForm, "country", $$v)
                                  },
                                  expression: "addressForm.country",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "contact-form-row", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _c("span", { staticStyle: { color: "#ff542f" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("Email address"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "email" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Email address",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.addressForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addressForm, "email", $$v)
                                  },
                                  expression: "addressForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _c("span", { staticStyle: { color: "#ff542f" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v("Phone number"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "phone" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Phone number",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.addressForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addressForm, "phone", $$v)
                                  },
                                  expression: "addressForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "button-container",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { staticClass: "cancel", on: { click: _vm.handleClose } },
                [_vm._v("cancel")]
              ),
              _c(
                "el-button",
                { staticClass: "confirm", on: { click: _vm.submit } },
                [_vm._v("confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "reminder",
          attrs: {
            width: "820px",
            title: "Order Reminder",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleReminderClose,
          },
        },
        [
          _c("div", { staticClass: "tips" }, [
            _c("img", {
              attrs: { src: require("@/assets/order/tip-1.png"), alt: "" },
            }),
            _c("span", { staticClass: "tips-text" }, [
              _vm._v(
                "Please transfer funds to the following account using offline methods, and provide your order number for our backend verification. We will confirm within one day. Thank you for your cooperation."
              ),
            ]),
          ]),
          _c("div", { staticClass: "form" }, [
            _c("div", { staticClass: "form-title" }, [
              _vm._v("Receiving account"),
            ]),
            _c("div", { staticClass: "form-content" }, [
              _c("div", { staticClass: "form-content-inner" }, [
                _c("div", { staticClass: "content-container" }, [
                  _c("span", { staticClass: "inner-title" }, [
                    _vm._v("Account Name:"),
                  ]),
                  _c("span", { staticClass: "inner-text" }, [
                    _vm._v(" Antony Edwords"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "content-container" },
                  [
                    _c("span", { staticClass: "inner-title" }, [
                      _vm._v("Card number:"),
                    ]),
                    _c("span", { staticClass: "inner-text" }, [
                      _vm._v(" 4539 6876 5432 1098"),
                    ]),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: "4539 6876 5432 1098",
                            expression: "'4539 6876 5432 1098'",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.handleCopy,
                            expression: "handleCopy",
                            arg: "success",
                          },
                        ],
                        staticClass: "copy-btn",
                      },
                      [_vm._v("Copy")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-content-inner" }, [
                _c("div", { staticClass: "content-container" }, [
                  _c("span", { staticClass: "inner-title" }, [_vm._v("bank:")]),
                  _c("span", { staticClass: "inner-text" }, [
                    _vm._v(" DBS Debit Card"),
                  ]),
                ]),
                _c("div", { staticClass: "content-container" }, [
                  _c("span", { staticClass: "inner-title" }, [
                    _vm._v("swift code:"),
                  ]),
                  _c("span", { staticClass: "inner-text" }, [
                    _vm._v(" W1695242884"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-content-inner" }, [
                _c(
                  "div",
                  { staticClass: "content-container" },
                  [
                    _c("span", { staticClass: "inner-title" }, [
                      _vm._v("Order Number:"),
                    ]),
                    _c("span", { staticClass: "inner-text" }, [
                      _vm._v(" " + _vm._s(_vm.orderNo)),
                    ]),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.orderNo,
                            expression: "orderNo",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.handleCopy,
                            expression: "handleCopy",
                            arg: "success",
                          },
                        ],
                        staticClass: "copy-btn",
                      },
                      [_vm._v("Copy")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "button-container",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "confirm",
                  on: { click: _vm.handleReminderSubmit },
                },
                [_vm._v("Get it")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right" }, [
      _c("span", [_vm._v("Contact Number")]),
      _c("span", { staticClass: "phone" }, [_vm._v("+6288123456789")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "add-img" }, [
      _c("img", {
        attrs: { src: require("@/assets/address/add-address.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }