<template>
  <div class="details-container">
    <div class="container">
      <div class="title">
        <div class="title-left">Order details</div>
        <div class="title-right">
          <a href="/orderHistory">Back to list</a>
        </div>
      </div>
      <div class="table-title">
        <div class="Number">
          <div class="inner-title">Order Number:</div>
          <div class="inner-content">
            <div>{{detailData.orderNo}}</div>
            <el-button class="copy-btn" v-clipboard:copy="detailData.orderNo" v-clipboard:success="handleCopy">Copy</el-button>
          </div>
        </div>
        <div class="Number">
          <div class="inner-title">Order Time:</div>
          <div class="inner-content">{{detailData.createTime}}</div>
        </div>
        <div class="Number">
          <div class="inner-title">Order status:</div>
          <div v-if="detailData.deliveryTrackingNo === null">
            <div class="inner-content" v-if="detailData.status === 0" style="color: #FF6912">unconfirmed</div>
            <div class="inner-content" v-else-if="detailData.status === 1">Confirmed</div>
          </div>
          <div class="inner-content" v-else-if="detailData.deliveryTrackingNo !== null">Delivered</div>
        </div>
        <div class="Number" style="margin-right: 0" v-if="detailData.deliveryTrackingNo !== null">
          <div class="inner-title">Tracking number:</div>
          <div class="inner-content">{{ detailData.deliveryTrackingNo }}</div>
        </div>
      </div>
      <div class="detail" v-if="orderVoLists.length > 0">
        <div class="detail-container" v-for="(item, index) in orderVoLists" :key="index">
          <div class="detail-left">
            <el-image
                style="width: 80px; height: 80px;margin-right: 40px"
                :src="item.thumbnail"
                fit="cover"
            >
            </el-image>
            <div class="right">
              <div class="name">{{item.productName}}</div>
              <div class="price">{{item.productStockSkuValue}}</div>
            </div>
          </div>
          <div class="detail-right">
            <div class="price">{{currency(item.price)}}</div>
            <div class="quantity">x{{item.quantity}}</div>
            <div class="amount">{{currency(item.totalPrice)}}</div>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <el-empty description="No product information available at the moment"></el-empty>
      </div>
      <div class="payment-container">
        <div class="payment-title">Payment method</div>
        <div class="info-container">
          <div class="info-inner">
            <div class="inner-title">Account Name:</div>
            <div class="inner-content">Antony Edwords</div>
          </div>
          <div class="info-inner">
            <div class="inner-title">bank:</div>
            <div class="inner-content">DBS Debit Card</div>
          </div>
          <div class="info-inner">
            <div class="inner-title">Card number:</div>
            <div class="inner-content">4539 6876 5432 1098</div>
          </div>
          <div class="info-inner">
            <div class="inner-title">swift code：</div>
            <div class="inner-content">W1695242884</div>
          </div>
        </div>
        <div class="tips">
          <img src="@/assets/order/tip.png" alt="">
          <span class="tips-text">When transferring funds to the receiving account, please provide your order number for our
            backend verification. Thank you for your cooperation.</span>
        </div>
      </div>
      <div class="subtotal-container">
        <div class="subtotal">
          <div class="sub-title">Subtotal</div>
          <div class="price">{{currency(detailData.totalAmount)}}</div>
        </div>
      </div>

    </div>
    <ContactUs></ContactUs>
  </div>
</template>

<script>
import ContactUs from "@/components/contact.vue";
import {getOrderDetail} from "@/api/order";
import {currency} from "../../../utils/util";

export default {
  name: "orderHistory",
  components: {ContactUs},
  data() {
    return {
      detailData: {},
      orderVoLists: []
    }
  },
  mounted() {
    const id = this.$route.query.orderId;
    console.log(id, 100)
    this.fetchDetail(id)
  },
  methods:{
    currency,
  //   获取详情
    async fetchDetail(id){
      const res = await getOrderDetail(id)
      const {code, data} = res
      if(code === 0){
        const {orderVoList} = data
        this.detailData = data
        if(orderVoList && orderVoList.length > 0){
          this.orderVoLists = orderVoList.map(item=>{
            return {
              ...item,
              productStockSkuValue:item.productStockSkuValue && JSON.parse(item.productStockSkuValue).join(' / ')
            }
          })
        }
      }
    },
  //   复制
    handleCopy(){
      this.$message.success('Replicating Success!')
    }
  }
}
</script>


<style lang="scss" scoped>
.details-container {
  .container {
    max-width: 1080px; /* 设置版心的最大宽度 */
    margin-left: auto;
    margin-right: auto;
    padding: 154px 0 80px;
    .title {
      font-weight: 600;
      font-size: 30px;
      color: #151515;
      line-height: 35px;
      text-align: left;
      font-style: normal;
      margin-bottom: 35px;
      display: flex;
      justify-content: space-between;
      &-right{
        a{
          font-weight: 400;
          font-size: 14px;
          color: #1C7FF1;
          line-height: 16px;
          text-align: right;
          font-style: normal;
        }
      }
    }
    .table-title{
      padding: 45px 0;
      border-bottom: 1px solid #DADADA;
      border-top: 1px solid #DADADA;
      display: flex;
      .Number{
        font-weight: 400;
        font-size: 16px;
        color: #454545;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        margin-right: 150px;
        .inner-title{
          font-weight: 400;
          font-size: 14px;
          color: #888888;
          line-height: 16px;
          text-align: left;
          margin-bottom: 8px;
        }
        .inner-content{
          display: flex;
          align-items: center;
          ::v-deep .el-button{
            padding: 0;
          }
          .copy-btn{
            width: 48px;
            height: 22px;
            background: #FFFFFF;
            border-radius: 100px 100px 100px 100px;
            border: 1px solid #DCDCDC;
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            font-style: normal;
            margin-left: 10px;
            cursor:pointer;
          }
        }
      }
    }

    .detail::-webkit-scrollbar {
      display: none;
    }
    .detail{
      padding: 16px 0;
      max-height: 500px;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 116px;
        .price{
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }
        .detail-left{
          width: 690px;
          display: flex;
          align-items: center;
          img{
            width: 80px;
            height: 80px;
            margin-right: 30px;
          }
          .right{
            text-align: left;
            .name{
              font-weight: 500;
              font-size: 16px;
              color: #151515;
              line-height: 19px;
              text-align: left;
              font-style: normal;
              margin-bottom: 12px;
            }
          }
        }
        .detail-right{
          min-width: 400px;
          display: flex;
          align-items: center;
          text-align: left;
          .price {
            text-align: left;
            width: 170px;
          }
          .quantity {
            color: #666666;
            width: 150px;
            text-align: left;
          }
        }
      }
    }
    .payment-container{
      padding-top: 29px;
      border-top: 1px solid #DADADA;
      .payment-title{
        font-family: Roboto, Roboto;
        font-weight: 600;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        margin-bottom: 28px;
      }
      .info-container{
        display: flex;
        align-items: center;
        margin-bottom: 34px;
        .info-inner{
          flex: 1;
          text-align: left;
          .inner-title{
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 14px;
            color: #888888;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            margin-bottom: 12px;
          }
          .inner-content{
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            line-height: 19px;
            text-align: left;
            font-style: normal;
          }
        }
      }
      .tips{
        width: 100%;
        height: 56px;
        background: #FFF6E5;
        border-radius: 5px 5px 5px 5px;
        display: flex;
        align-items: center;
        margin-bottom: 36px;
        img{
          width: 18px;
          height: 18px;
          margin-left: 18px;
          margin-right: 16px;
        }
        .tips-text{
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 14px;
          color: #454545;
          line-height: 16px;
          text-align: left;
          font-style: normal;
        }

      }
    }
    .subtotal-container {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 50px 0 125px 0;
      border-top: 1px solid #DADADA;
      .subtotal{
        display: flex;
        justify-content: space-between;
        width: 460px;
        .sub-title {
          font-weight: 400;
          font-size: 16px;
          color: #151515;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }
        .price{
          font-weight: 500;
          font-size: 18px;
          color: #151515;
          line-height: 21px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
}
</style>
