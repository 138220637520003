import request from '@/utils/request'

// 配件型号
export function getAccessoryModel(params) {
    return request({
        url: '/accessory/model',
        method: 'get',
        params
    })
}

// 配件属性
export function getAccessoryAttribute(modelId) {
    return request({
        url: `/accessory/attribute?modelId=${modelId}`,
        method: 'get'
    })
}

// 配件列表
export function getAccessoryList(params) {
    return request({
        url: '/accessory/product',
        method: 'get',
        params
    })
}

// 配件详情
export function getAccessoryDetail(accessoryId) {
    return request({
        url:  `/accessory/productDetail/${accessoryId}`,
        method: 'get'
    })
}
