<template>
  <el-dialog :visible.sync="visible"  width="1140px" :before-close="handleClose" v-loading="loading.dialogLoading">
    <div class="detail-container" v-loading="loading.saveLoading">
      <div class="top">
        <div class="left" v-if="productDetail.images && productDetail.images.length > 0">
          <div class="inner-left">
            <div class="img-container" :class="{active: currentBannerIndex === index}" v-for="(item, index) in productDetail.images" :key="item.id" @click="handleChangeBanner(item, index)">
              <video v-if="item.type === 'video'" width="72" height="72">
                <source
                    :src="item.url"
                    type="video/mp4">
                您的浏览器不支持视频标签。
              </video>
              <el-image
                  v-else
                  style="width: 72px; height: 72px"
                  :src="item.url"
                  fit="cover"
                  v-loading="loading.imgLoading.smallImgLoading"
                  @load="()=>loading.imgLoading.smallImgLoading = false"
              >
              </el-image>
            </div>
          </div>
          <div class="inner-right">
            <video v-if="bannerType === 'video'" width="400" height="400" autoplay muted controls>
              <source
                  :src="bannerImageUrl"
                  type="video/mp4">
              您的浏览器不支持视频标签。
            </video>
            <el-image
                v-else
                style="width: 400px; height: 400px"
                :src="bannerImageUrl"
                fit="cover"
                v-loading="loading.imgLoading.bigImgLoading"
                @load="()=>loading.imgLoading.bigImgLoading = false"
            >
            </el-image>
          </div>
        </div>
        <div class="right">
          <div class="right-title">
            <div class="right-title-top">{{productDetail.name}}</div>
            <div class="right-title-center">
              <span v-if="productDetail.models && Object.keys(productDetail.models).length > 0">{{ Object.values(productDetail.models).join(', ') }}</span>
            </div>
            <div class="right-title-foot price">
              <span v-if="!checkedAttributesStock || Object.keys(checkedAttributesStock).length === 0">{{currency(productDetail.discountPrice)}}</span>
              <span v-else>{{currency(checkedAttributesStock.discountPrice)}}</span>
              <span  v-if="productDetail.discountPrice !== productDetail.price || checkedAttributesStock.discountPrice !== checkedAttributesStock.price ">
                <span style="margin-left: 16px;color: #BABABA;font-weight: 400;font-size: 16px;text-decoration: line-through" v-if="!checkedAttributesStock || Object.keys(checkedAttributesStock).length === 0">{{currency(productDetail.price)}}</span>
              <span style="margin-left: 16px;color: #BABABA;font-weight: 400;font-size: 16px;text-decoration: line-through" v-else>{{currency(checkedAttributesStock.price)}}</span>
              </span>
            </div>
          </div>
          <div v-if="productDetail.stocks && productDetail.stocks.length > 0">
            <div class="right-attributes" v-for="(item, index) in productDetail.attributes" :key="item.id">
              <div class="attributes-title">{{ item.name }}:</div>
              <el-tag type="info" :class="{tagActive: item.currentTagIndex === valueIndex}" style="margin-bottom: 10px"  effect="plain" v-for="(value, valueIndex) in item.values" :key="value.id" @click="checkedAttributeTag(item,value, index, valueIndex)">{{value.name}}</el-tag>
            </div>
          </div>
          <div class="btn-container">
            <div class="right-num">
              <div class="right-num-title">Purchase quantity:</div>
              <div class="right-num-number"><el-input-number v-model="selectedQuantity" :precision="0" :min="1"></el-input-number></div>
            </div>
            <div class="right-btn" :class="{disabled: isDisabled}">
              <el-button class="submit-btn cart" @click="handleAddToCart('toCart')" :disabled="isSaving">Add To Cart</el-button>
              <el-button class="submit-btn" @click="handleAddToCart('toOrder')" :disabled="isSaving">Order Now</el-button>
            </div>
          </div>

        </div>
      </div>
      <div class="foot" v-if="productDetail.desc">
        <div class="foot-title">
          DESCRIPTION
          <div class="icon"></div>
        </div>
        <div class="foot-text" v-html="productDetail.desc"></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { currency } from '@/utils/util'
import {getProductDetail} from '@/api/product'
import { edit} from "@/api/cart";
import {getAccessoryDetail} from "@/api/assessories";
import _ from "lodash";
import {ORDER_SOURCE} from '@/tool/enum'
export default {
  name: "productDetail",
  data(){
    return{
      visible: false,
      productDetail: {},
      bannerImageUrl: '',
      bannerType: 'video',
      currentBannerIndex: 0,
      combinationAttribute: [],
      checkedAttributesStock: {},
      selectedQuantity: 1,
      productType: '',
      ORDER_SOURCE,
      isDisabled: false,
      loading: {
        dialogLoading: false,
        imgLoading: {
          smallImgLoading: true,
          bigImgLoading: true
        },
        saveLoading: false
      },
      timer: null,


      isThrottled: false,
      isSaving: false
    }
  },
  computed: {
    hasToken() {
      return !!this.$store.getters.token
    }
  },

  methods:{
    currency,
    // 获取产品详情
    async fetchProductDetail(id){
      this.loading.dialogLoading = true
      let action
      if(this.productType === 'accessory'){
        action = getAccessoryDetail
      }else{
        action = getProductDetail
      }
      const res = await action(id)
      const {code, data} = res
      if(code === 0){
        const parsedAttributes = data.attributes && JSON.parse(data.attributes).map(attr => ({
          ...attr,
          currentTagIndex: null
        }))
        const images = JSON.parse(data.images).filter(item=>item.tag !== 'del') ?? []
        if (data.video) {
          images.unshift({ url: data.video, type: 'video' })
        }
        if(images && images.length > 0){
          this.bannerType = images[0].type
          this.bannerImageUrl = images[0].url
        }
        this.productDetail = {
          ...data,
          images,
          attributes: parsedAttributes,
          stocks: data.stocks && data.stocks.length > 0 && data.stocks.map(item=>{
            return {
              ...item,
              sku: JSON.parse(item.sku),
            }
          })
        }
        this.loading.dialogLoading = false
        console.log(images, 116)
        console.log(this.productDetail, 'ProductDetail----------')
        console.log(this.checkedAttributesStock, 112)
        console.log(this.bannerImageUrl, 149)
      }
    },
    handleChangeBanner(item, index){
      console.log(item, 77)
      this.bannerType = item.type
      this.currentBannerIndex = index
      this.bannerImageUrl = item.url
    },
    checkedAttributeTag(item,value, attributeIndex, valueIndex){
      console.log(item, '----------')
      console.log(value, '----------')
      console.log(attributeIndex, valueIndex, 111)
      this.selectedQuantity = 1
      this.bannerType = 'image'
      this.productDetail.attributes[attributeIndex].currentTagIndex = valueIndex
      this.combinationAttribute = this.combinationAttribute || []
      // 检查是否已存在相同属性索引的组合
      const existingIndex = this.combinationAttribute.findIndex(
          attr => attr.attributeIndex === attributeIndex
      )
      if (existingIndex !== -1) {
        this.combinationAttribute[existingIndex] = {
          attributeIndex,
          valueId: value.id
        }
      } else {
        this.combinationAttribute.push({
          attributeIndex,
          valueId: value.id
        })
      }
      console.log('组合属性:', this.combinationAttribute)
      const checkedAttributesSku = this.combinationAttribute.map(item=>item.valueId)
      console.log(checkedAttributesSku, 139)
      const {stocks} = this.productDetail
      if(stocks){
        // 判断选择属性是否存在
        const exists = stocks.find(stock =>
            _.isEqual(_.sortBy(stock.sku), _.sortBy(checkedAttributesSku))
        );
        console.log(exists, 200)
        if(exists){
          // 判断是否上架
          if(exists.status === 0){
            this.isDisabled = true
          }else{
            this.isDisabled = false
          }
          this.checkedAttributesStock = exists
          const { cropThumbnail } = exists
          this.bannerImageUrl = cropThumbnail
          console.log(this.bannerImageUrl, 202)
        }else{
          this.isDisabled = true
        }
      }
    },
    handleAddToCart(type) {
      // 节流
      if (this.isThrottled) return;
      this.isThrottled = true;
      this.save(type);
      setTimeout(() => {
        this.isThrottled = false;
      }, 500);
    },
    async save(type){
      this.loading.saveLoading = true
      console.log('添加购物车', this.isSaving)
      if (this.isSaving) return;
      this.isSaving = true;
      if(!this.hasToken){
        this.$router.push({ path: '/login' })
        return
      }
      console.log(this.checkedAttributesStock, 222)
      if (this.productDetail.stocks && this.productDetail.stocks.length > 0) {
        if (!this.checkedAttributesStock || Object.keys(this.checkedAttributesStock).length === 0) {
          this.$message.warning('Please select product specifications!')
          this.loading.saveLoading = false
          this.isSaving = false
          return
        }
      }
      const data = {
        productId: this.productDetail.id,
        quantity: this.selectedQuantity,
        productStockId: this.checkedAttributesStock.id
      }
      console.log(this.productDetail, 208)
      console.log(this.checkedAttributesStock, 171)
      console.log(this.checkedAttributesStock.id, 232)
      const {id, skuValue, discountPrice, cropThumbnail} = this.checkedAttributesStock
      const confirmOrderProductDetail = [{
        productName: this.productDetail.name,
        productStockSkuValue: skuValue && JSON.parse(skuValue).join(' / '),
        productStockDiscountPrice: discountPrice,
        productDiscountPrice: this.productDetail.discountPrice,
        thumbnail: cropThumbnail || this.productDetail.cropThumbnail,
        quantity: this.selectedQuantity,
        productId: this.productDetail.id,
        productStockId: id
      }]
      console.log(confirmOrderProductDetail, 210)
      if(type === 'toOrder'){
        localStorage.setItem('confirmOrderProductDetail', JSON.stringify(confirmOrderProductDetail))
        localStorage.setItem('source', ORDER_SOURCE.Detail)
        this.$router.push({
          path: '/confirmOrder'
        })
        this.visible = false
      }else{
        const res = await edit({...data})
        const {code} = res
        if (code === 0) {
          if(type === 'toCart'){
            this.$message.success('Added successfully!')
            await this.$store.dispatch('cart/fetch')
          }
          this.visible = false
          this.isSaving = false
          this.loading.saveLoading = false
        }
      }
    },
    resetData(){
      this.currentBannerIndex = 0
      this.bannerImageUrl = ''
      this.productDetail = {}
      this.checkedAttributesStock = {};
      this.selectedQuantity = 0
      this.combinationAttribute = []
      this.isDisabled = false
    },
    async handleOpen(id, productType){
      console.log(id, 73)
      const {resetData, fetchProductDetail} = this
      this.productType = productType
      resetData()
      await fetchProductDetail(id)
      this.visible = true
    },
    handleClose(){
      this.visible = false
      this.loading.imgLoading.smallImgLoading = true
      this.loading.imgLoading.bigImgLoading = true
    }
  }
}
</script>


<style scoped lang="scss">
::v-deep .el-dialog__body{
  padding: 0;
}
::v-deep .el-dialog{
  border-radius: 18px;
}
.detail-container{
  padding: 60px;
  .top{
    display: flex;
    .left{
      display: flex;
      justify-content: space-between;
      margin-right: 60px;
      align-items: center;
      .inner-left{
        display: flex;
        height: 400px;
        overflow-y: scroll;
        flex-direction: column;
        .img-container{
          width: 72px;
          height: 72px;
          border: 1px solid #E3E3E3;
          margin-bottom: 16px;
          margin-right: 20px;
          img{
            width: 100%;
          }
        }
        .active{
          border: 2px solid #ff7829;
        }
      }
      .inner-right{
        width: 400px;
        height: 400px;
        background: #bbc5cb;
      }
    }
    .right{
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-title{
        margin-bottom: 50px;
        &-top{
          font-weight: bold;
          font-size: 24px;
          color: #151515;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          margin-bottom: 12px;
        }
        &-center{
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          margin-bottom: 22px;
        }
        .price{
          margin-top: 10px;
          font-weight: 500;
          font-size: 20px;
          color: #FF6912;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }

        .price-title{
          font-weight: 400;
          font-size: 20px;
          color: #666666;
          line-height: 23px;
          text-align: left;
          font-style: normal;
        }
      }
      &-attributes{
        margin-bottom: 30px;
        .attributes-title{
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          margin-bottom: 10px;
        }
        ::v-deep .el-tag{
          margin-right: 10px;
        }
        .tagActive{
          border: 1px solid #151515;
          color: #151515;
        }
      }
      .btn-container{
        .right-num{
          &-title{
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            margin-bottom: 13px;
          }
        }
        .right-btn{
          display: flex;
          .submit-btn{
            width: 180px;
            height: 50px;
            background: #151515;
            border-radius: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            margin-top: 30px;
          }
          .cart{
            border: 1px solid #151515;
            background: transparent;
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            font-style: normal;
          }
        }
        .disabled {
          opacity: 0.5 !important;
          pointer-events: none;
        }
      }

    }
  }
  .foot{
    margin-top: 70px;
    &-title{
      font-weight: bold;
      font-size: 21px;
      color: #151515;
      line-height: 25px;
      text-align: left;
      border-bottom: 1px solid #DCDCDC;
      padding-bottom: 15px;
      position: relative;
      .icon{
        width: 77px;
        height: 4px;
        background: #151515;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &-text{
      font-weight: 400;
      font-size: 19px;
      color: #666666;
      line-height: 30px;
      text-align: left;
      padding-top: 28px;
      font-style: normal;
    }
  }
}
</style>
