var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accessories-container" },
    [
      _vm.isSearch
        ? _c("div", { staticClass: "search" }, [
            _c(
              "div",
              { staticClass: "search-container" },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "Search for accessories",
                    "prefix-icon": "el-icon-search",
                  },
                  on: { change: _vm.handleInput },
                  model: {
                    value: _vm.filters.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "name", $$v)
                    },
                    expression: "filters.name",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "search-result" }, [
              _c("div", { staticClass: "search-result-left" }, [
                _vm._v("Found " + _vm._s(_vm.resultNum) + " results"),
              ]),
              _c(
                "div",
                { staticClass: "search-result-right" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("brand:")]),
                  _c(
                    "el-dropdown",
                    { on: { command: _vm.handleCommand } },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v(" " + _vm._s(_vm.currentBrandName)),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "custom-dropdown-menu",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.brandList, function (item) {
                          return _c(
                            "el-dropdown-item",
                            { key: item.id, attrs: { command: item } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("ProductIndex", {
        ref: "productList",
        attrs: {
          "is-search": _vm.isSearch,
          currentBrandId: _vm.currentBrandId,
        },
        on: {
          "update-resultNum": _vm.updateResultNum,
          "update-brandIndex": _vm.updateBrandIndex,
        },
      }),
      _c("Contact"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }