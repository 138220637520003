var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "history-container" },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("Order History")]),
          _vm._m(0),
          _vm.orderList.length > 0
            ? _c(
                "div",
                _vm._l(_vm.orderList, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "table-body" },
                    [
                      _c("div", { staticClass: "body-head" }, [
                        _c("div", { staticClass: "order-no" }, [
                          _c("span", { staticClass: "tip" }, [
                            _vm._v("Order Number:"),
                          ]),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(item.orderNo)),
                          ]),
                        ]),
                        _c("div", { staticClass: "order-no" }, [
                          _c("span", { staticClass: "tip" }, [
                            _vm._v("Order Time:"),
                          ]),
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(item.createTime)),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "body-content" }, [
                        _c(
                          "div",
                          { staticClass: "table-head-inner" },
                          [
                            _vm._l(
                              item.orderDetailVos.slice(0, 3),
                              function (i, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      width: "60px",
                                      height: "60px",
                                      "margin-right": "3px",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "60px",
                                        height: "60px",
                                        "border-radius": "4px",
                                      },
                                      attrs: { src: i.thumbnail, alt: "" },
                                    }),
                                  ]
                                )
                              }
                            ),
                            _c("div", { staticClass: "quantity" }, [
                              _vm._v(
                                _vm._s(item.quantity) + " pieces in total"
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "subtotal" }, [
                          _vm._v(_vm._s(_vm.currency(item.totalAmount))),
                        ]),
                        item.deliveryTrackingNo === null && item.status === 0
                          ? _c(
                              "div",
                              {
                                staticClass: "status",
                                staticStyle: { color: "#FF560E" },
                              },
                              [_vm._v("Unconfirmed")]
                            )
                          : item.deliveryTrackingNo === null &&
                            item.status === 1
                          ? _c("div", { staticClass: "status" }, [
                              _vm._v("Confirmed"),
                            ])
                          : item.deliveryTrackingNo !== null
                          ? _c("div", { staticClass: "status" }, [
                              _vm._v("Delivered"),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "operate",
                            staticStyle: {
                              "text-decoration": "underline",
                              color: "#1C7FF1",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toOrderDetail(item)
                              },
                            },
                          },
                          [_vm._v("view")]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _c(
                "div",
                [
                  _c("el-empty", {
                    attrs: { description: "No orders at the moment" },
                  }),
                ],
                1
              ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pagination.total > 0,
                expression: "pagination.total > 0",
              },
            ],
            staticStyle: { "text-align": "right" },
            attrs: {
              "auto-scroll": false,
              total: _vm.pagination.total,
              page: _vm.pagination.current,
              limit: _vm.pagination.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pagination, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.pagination, "size", $event)
              },
              pagination: _vm.fetch,
            },
          }),
        ],
        1
      ),
      _c("ContactUs"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-head" }, [
      _c("div", { staticClass: "table-head-inner" }, [_vm._v("details")]),
      _c("div", { staticClass: "subtotal" }, [_vm._v("Subtotal")]),
      _c("div", { staticClass: "status" }, [_vm._v("Order status")]),
      _c("div", { staticClass: "operate" }, [_vm._v("Operate")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }